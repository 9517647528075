import {call, put, takeLatest} from 'redux-saga/effects';
import {getTables} from '~/api';
import {load, successLoad} from './slice';

function* handleLoad() {
  try {
    const res = yield call(getTables);
    yield put(successLoad(res.data));
  } catch (err) {}
}

export function* watchTable() {
  yield takeLatest(load, handleLoad);
}
