import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@material-ui/core';
import React, {useEffect} from 'react';
import _ from 'lodash';
import {IMetaTable} from '~/features/MetaTable/slice';
import {useDispatch, useSelector} from 'react-redux';
import {DATA, load} from '~/features/Data/slice';
import {RootState} from '~/features/rootReducer';
import DnDBox, {DnDBoxTypes} from './DnDBox';

export interface IDataTableProps {
  /**
   * table meta data
   */
  meta: IMetaTable;
}

const DataTable: React.FC<IDataTableProps> = props => {
  const {
    meta: {headers, tablename},
  } = props;
  const dispatch = useDispatch();
  const data = useSelector((state: RootState) =>
    _.find(state[DATA].data, d => d.name === tablename),
  );

  useEffect(() => {
    if (tablename) {
      dispatch(load(tablename));
    }
  }, [dispatch, tablename]);

  return (
    <TableContainer style={{maxHeight: 400}}>
      <Table stickyHeader>
        <TableHead>
          <TableRow>
            {_.map(headers, header => (
              <TableCell key={header.var}>
                <DnDBox
                  item={{
                    name: header.var,
                    type: DnDBoxTypes.Variable,
                    tablename,
                  }}
                />
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {_.map(data?.data, d => (
            <TableRow key={`row_${d.id}`}>
              {_.map(headers, header => (
                <TableCell>{d[header.colName]}</TableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default DataTable;
