import React from 'react';

const Mean = () => {
  return (
    <svg
      width="36"
      height="36"
      viewBox="0 0 25 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="1.5"
        y="1.5"
        width="5.75622"
        height="5.75622"
        fill="#EE4D87"
        stroke="#EE4D87"
      />
      <rect
        x="9.60742"
        y="9.60742"
        width="5.75622"
        height="5.75622"
        fill="#EE4D87"
        stroke="#EE4D87"
      />
      <rect
        x="1.5"
        y="11.4707"
        width="4.90911"
        height="4.90911"
        fill="#605E5C"
        stroke="#605E5C"
      />
      <rect
        x="1.5"
        y="18.5615"
        width="4.90911"
        height="4.90911"
        fill="#605E5C"
        stroke="#605E5C"
      />
      <rect
        x="8.59082"
        y="18.5615"
        width="4.90911"
        height="4.90911"
        fill="#605E5C"
        stroke="#605E5C"
      />
      <rect
        x="17.7144"
        y="17.7148"
        width="5.75622"
        height="5.75622"
        fill="#EE4D87"
        stroke="#EE4D87"
      />
      <rect
        x="11.4746"
        y="1.5"
        width="4.90911"
        height="4.90911"
        fill="#605E5C"
        stroke="#605E5C"
      />
      <rect
        x="18.5908"
        y="1.5"
        width="4.90911"
        height="4.90911"
        fill="#605E5C"
        stroke="#605E5C"
      />
      <rect
        x="18.5649"
        y="8.59082"
        width="4.90911"
        height="4.90911"
        fill="#605E5C"
        stroke="#605E5C"
      />
      <path
        d="M4.97461 3.13672V6H4.35938V3.83203C4.32552 3.86198 4.28516 3.89062 4.23828 3.91797C4.19271 3.94401 4.14388 3.9681 4.0918 3.99023C4.03971 4.01107 3.98568 4.0293 3.92969 4.04492C3.8737 4.05924 3.81836 4.06966 3.76367 4.07617V3.55664C3.92383 3.50977 4.07487 3.44987 4.2168 3.37695C4.35872 3.30404 4.48698 3.22396 4.60156 3.13672H4.97461Z"
        fill="white"
      />
      <path
        d="M12.1875 13.4883H13.375V14H11.5117V13.7891C11.5117 13.6458 11.5358 13.5176 11.584 13.4043C11.6322 13.2897 11.6927 13.1875 11.7656 13.0977C11.8385 13.0065 11.918 12.9264 12.0039 12.8574C12.0911 12.7871 12.1738 12.724 12.252 12.668C12.334 12.6094 12.4056 12.5534 12.4668 12.5C12.5293 12.4466 12.5814 12.3939 12.623 12.3418C12.666 12.2884 12.6979 12.235 12.7188 12.1816C12.7396 12.127 12.75 12.069 12.75 12.0078C12.75 11.888 12.7161 11.7975 12.6484 11.7363C12.5807 11.6751 12.4772 11.6445 12.3379 11.6445C12.097 11.6445 11.8665 11.7402 11.6465 11.9316V11.3887C11.89 11.2311 12.1647 11.1523 12.4707 11.1523C12.6126 11.1523 12.7396 11.1712 12.8516 11.209C12.9648 11.2454 13.0605 11.2982 13.1387 11.3672C13.2168 11.4362 13.276 11.5202 13.3164 11.6191C13.3581 11.7168 13.3789 11.8262 13.3789 11.9473C13.3789 12.0762 13.3587 12.1908 13.3184 12.291C13.2793 12.3913 13.2266 12.4824 13.1602 12.5645C13.0951 12.6465 13.0195 12.722 12.9336 12.791C12.8477 12.8587 12.7585 12.9245 12.666 12.9883C12.6035 13.0326 12.543 13.0768 12.4844 13.1211C12.4271 13.1641 12.3763 13.207 12.332 13.25C12.2878 13.2917 12.2526 13.3327 12.2266 13.373C12.2005 13.4134 12.1875 13.4518 12.1875 13.4883Z"
        fill="white"
      />
      <path
        d="M19.5781 21.9082V21.3711C19.7656 21.5078 19.9844 21.5762 20.2344 21.5762C20.3919 21.5762 20.5143 21.5423 20.6016 21.4746C20.6901 21.4069 20.7344 21.3125 20.7344 21.1914C20.7344 21.0664 20.6797 20.9701 20.5703 20.9023C20.4622 20.8346 20.3132 20.8008 20.123 20.8008H19.8633V20.3281H20.1035C20.4681 20.3281 20.6504 20.207 20.6504 19.9648C20.6504 19.737 20.5104 19.623 20.2305 19.623C20.043 19.623 19.8607 19.6836 19.6836 19.8047V19.3008C19.8802 19.2018 20.1094 19.1523 20.3711 19.1523C20.6576 19.1523 20.8802 19.2168 21.0391 19.3457C21.1992 19.4746 21.2793 19.6419 21.2793 19.8477C21.2793 20.2135 21.0938 20.4427 20.7227 20.5352V20.5449C20.9206 20.5697 21.0768 20.6419 21.1914 20.7617C21.306 20.8802 21.3633 21.026 21.3633 21.1992C21.3633 21.4609 21.2676 21.668 21.0762 21.8203C20.8848 21.9727 20.6204 22.0488 20.2832 22.0488C19.9941 22.0488 19.7591 22.002 19.5781 21.9082Z"
        fill="white"
      />
    </svg>
  );
};

export default Mean;
