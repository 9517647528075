import {createSlice, PayloadAction} from '@reduxjs/toolkit';

export interface ITable {
  /**
   * 아이디 primary key
   */
  id: number;

  /**
   * 화면에 보여지는 이름
   */
  name: string;

  /**
   * 업로드 파일명
   */
  filename: string;

  /**
   * 업로드 파일 타입
   */
  filetype: string;
}

interface IState {
  loading: boolean;
  tables: ITable[];
}

let initialState: IState = {
  loading: false,
  tables: [],
};

const slice = createSlice({
  name: 'table',
  initialState,
  reducers: {
    load: state => {
      state.loading = true;
    },
    successLoad: (state, {payload}: PayloadAction<ITable[]>) => {
      state.loading = false;
      state.tables = payload;
    },
  },
});

export const {load, successLoad} = slice.actions;
export const TABLE = slice.name;
export default slice.reducer;
