import React from 'react';
import {Paper, withStyles} from '@material-ui/core';
import NavButton from './NavButton';
import _ from 'lodash';
import {Theme} from '@material-ui/core/styles';

export interface INavItem {
  icon: React.ReactNode;
  title: string;
  linkto: string;
  disabled: boolean;
  active: boolean;
}

export interface INavMenuProps {
  items: INavItem[];
}

const StyledPaper = withStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    width: 148,
    height: 'calc(100vh - 48px)',
    borderRight: `1px solid ${theme.palette.grey['300']}`,
  },
}))(Paper);

const NavMenu: React.FunctionComponent<INavMenuProps> = props => {
  const {items} = props;

  return (
    <StyledPaper square elevation={0}>
      {_.map(items, item => (
        <NavButton
          key={`nav-menu-${item.title}`}
          icon={item.icon}
          title={item.title}
          linkto={item.linkto}
          disabled={item.disabled}
          active={item.active}
        ></NavButton>
      ))}
    </StyledPaper>
  );
};
export default NavMenu;
