import React, {useEffect, useRef} from 'react';
import {
  Container,
  createStyles,
  makeStyles,
  Theme,
  Typography,
  withStyles,
} from '@material-ui/core';
import DataViewHeader from './DataViewHeader';
import {useTheme} from '@material-ui/core';
import clsx from 'clsx';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    hash1: {
      position: 'absolute',
      width: '100%',
      height: '100%',
    },
    hash2: {
      width: '100%',
      height: '100%',
    },
    container: {
      padding: 8,
      zIndex: 99,
    },
    center: {
      height: '100%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
  }),
);

export interface IDataViewContainerProps {
  children: React.ReactNode;
  backStyle?: boolean;
  center?: boolean;
}

const StyledContainer = withStyles((theme: Theme) => ({
  root: {
    maxWidth: 1920,
    width: '100%',
    height: '100%',
    display: 'flex',
    position: 'relative',
    flexDirection: 'column',
    padding: 0,
    margin: 0,
    border: `1px solid ${theme.palette.grey['300']}`,
    borderRadius: 6,
    textAlign: 'center',
  },
}))(Container);

const StyledTypography = withStyles((theme: Theme) => ({
  root: {
    zIndex: 1,
    display: 'flex',
    height: '85%',
    justifyContent: 'center',
    alignItems: 'center',
  },
}))(Typography);

const DataViewContainer: React.FC<IDataViewContainerProps> = ({
  children,
  backStyle,
  center,
}) => {
  const theme = useTheme() as Theme;
  const [isHeight, setIsHeight] = React.useState('74.8%');
  const [wLineCount, setWLineCount] = React.useState(0);
  const [hLineCount, setHLineCount] = React.useState(0);

  const inputRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    let cWidth = inputRef.current?.clientWidth ?? 0;
    let cHeigth = inputRef.current?.clientHeight ?? 0;
    if (cHeigth !== 0 && cWidth !== 0) {
      setWLineCount(Math.round(cWidth / 130) - 1);
      setHLineCount(Math.round(cHeigth / 130) - 1);
    }
  }, [inputRef]);

  const handleMinimize = () => {
    setIsHeight('13.8%');
  };

  const handleMaximize = () => {
    setIsHeight('74.8%');
  };
  const classes = useStyles();
  return (
    <StyledContainer ref={inputRef}>
      {backStyle && (
        <div className={classes.hash1}>
          <svg className={classes.hash2}>
            <g id="line-group">
              <line
                strokeDasharray="8, 8"
                x1="0"
                y1="10"
                x2="5000"
                y2="10"
                stroke={theme.palette.grey['300']}
              />
            </g>
            {(() => {
              const result = [];
              for (let i = 1; i <= hLineCount; i++) {
                result.push(
                  <use
                    key={`use-${i}`}
                    xlinkHref="#line-group"
                    x="0"
                    y={130 * i}
                  />,
                );
              }
              return result;
            })()}
            <g id="line-group2">
              <line
                strokeDasharray="8, 8"
                x1="10"
                y1="0"
                x2="10"
                y2="5000"
                stroke={theme.palette.grey['300']}
              />
            </g>
            {(() => {
              const result = [];
              for (let i = 1; i <= wLineCount; i++) {
                result.push(
                  <use
                    key={`use-2-${i}`}
                    xlinkHref="#line-group2"
                    x={130 * i}
                    y="0"
                  />,
                );
              }
              return result;
            })()}
          </svg>
        </div>
      )}
      <DataViewHeader onMinimize={handleMinimize} onMaximize={handleMaximize} />
      <div className={clsx(classes.container, center && classes.center)}>
        {children}
      </div>
    </StyledContainer>
  );
};

export default DataViewContainer;
