import React from 'react';
import {IconButton} from './IconButton';
import ArrowDropUpOutlinedIcon from '@material-ui/icons/ArrowDropUpOutlined';
import ArrowDropDownOutlinedIcon from '@material-ui/icons/ArrowDropDownOutlined';
import {makeStyles, Theme, createStyles, withStyles} from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    grow: {
      display: 'flex',
      justifyContent: 'flex-end',
      padding: theme.spacing(1),
      borderBottom: `1px solid ${theme.palette.grey['300']}`,
    },
  }),
);

const StyledIconButton = withStyles(theme => ({
  root: {
    color: theme.palette.primary.dark,
    minHeight: '32px',
    minWidth: '32px',
  },
}))(IconButton);

export interface DataViewHeaderProps {
  onMaximize: () => void;
  onMinimize: () => void;
}

const DataViewHeader: React.FC<DataViewHeaderProps> = props => {
  const {onMaximize, onMinimize} = props;
  const classes = useStyles();
  return (
    <div className={classes.grow}>
      <StyledIconButton
        icon={<ArrowDropUpOutlinedIcon style={{fontSize: 35}} />}
        size="large"
        onClick={onMinimize}
      />
      <StyledIconButton
        icon={<ArrowDropDownOutlinedIcon style={{fontSize: 35}} />}
        size="large"
        onClick={onMaximize}
      />
    </div>
  );
};

export default DataViewHeader;
