import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface IState {
  loading: boolean;
  progress: number;
}

let initialState: IState = {
  loading: false,
  progress: 0,
};

const slice = createSlice({
  name: "upload-file",
  initialState,
  reducers: {
    uploadRequest: (state, _action: PayloadAction<File[]>) => {
      state.loading = true;
      state.progress = 0;
    },
    uploadSuccess: (state) => {
      state.loading = false;
    },
  },
});

export const { uploadRequest, uploadSuccess } = slice.actions;
export const UPLOADFILE = slice.name;
export default slice.reducer;
