import {PayloadAction} from '@reduxjs/toolkit';
import {call, put, takeLatest} from 'redux-saga/effects';
import {getMetaTables} from '~/api/MetaTable';
import {append, load} from './slice';

function* handleLoad({payload}: PayloadAction<number[]>) {
  try {
    const res = yield call(getMetaTables, payload);
    yield put(append(res.data));
  } catch (err) {}
}

export function* watchMetaTable() {
  yield takeLatest(load, handleLoad);
}
