import {makeStyles} from '@material-ui/core/styles';
import React from 'react';
import {useDrag, useDrop} from 'react-dnd';
import VariableIcon from '../icons/Variable';
import {IToolOptions, ProcessToolTypes} from './ProcessTool';
import clsx from 'clsx';

const useStyles = makeStyles({
  root: {
    '&>svg': {
      display: 'block',
      padding: 6,
    },
  },
  svgMargin: {
    '&>svg': {
      marginRight: 4,
    },
  },
  connector: {
    width: 6,
    height: 6,
    border: '3px solid #113366',
    borderRadius: 6,
    backgroundColor: 'white',
    zIndex: 10,
  },
  connectorRight: {
    marginLeft: -6,
  },
  connectorLeft: {
    marginRight: -6,
  },
  container: {
    display: 'flex',
    alignItems: 'center',
  },
  variableIcon: {
    marginRight: 6,
  },
  variableContainer: {
    display: 'flex',
    alignItems: 'center',
    background: '#0078D4',
    borderRadius: 3,
    color: 'white',
    padding: 6,
  },
});

export enum DnDBoxTypes {
  Tool = 'Tool',
  Variable = 'Variable',
  Result = 'Result',
}

export interface IItem {
  id?: string;
  icon?: React.ReactNode;
  name: string;
  type: DnDBoxTypes;
  toolType?: ProcessToolTypes;
  left?: number;
  top?: number;
  connector?: boolean;
  options?: IToolOptions[];
}

export type IPreproc = IItem;
export type IVariable = IItem & {tablename: string};

export interface IDnDBoxProps {
  id?: string;
  item: IPreproc | IVariable;
  hideSourceOnDrag?: boolean;
  onPlayground?: boolean;
  absolute?: boolean;
  classes?: {
    root: string;
  };
  className?: string;
  onClick?: () => void;
  children?: React.ReactNode;
}

const DnDBox = React.forwardRef<HTMLDivElement, IDnDBoxProps>(
  (
    {
      id,
      item,
      hideSourceOnDrag,
      onPlayground,
      absolute,
      classes,
      className,
      onClick,
      children,
    },
    ref,
  ) => {
    const iclasses = useStyles();
    const {icon, name, left, top, type} = item;
    const [{isDragging}, drag] = useDrag({
      item: item,
      collect: monitor => ({
        isDragging: monitor.isDragging(),
      }),
    });

    const [, drop] = useDrop({
      accept: Object.keys(DnDBoxTypes),
      drop: () => item,
    });

    const [, drag2, preview] = useDrag({
      item: {...item, connector: true},
      collect: monitor => ({
        isDragging: monitor.isDragging(),
      }),
    });

    const position: React.CSSProperties = absolute
      ? {
          position: 'absolute',
          left,
          top,
        }
      : {};

    if (isDragging && hideSourceOnDrag) {
      return <div id={id} ref={drag} style={{...position}} />;
    }

    return (
      <div
        id={id}
        ref={preview}
        className={className}
        style={{...position}}
        onClick={onClick}
      >
        <div ref={ref}>
          <div
            ref={drop}
            className={
              type === DnDBoxTypes.Variable && onPlayground
                ? iclasses.variableContainer
                : iclasses.container
            }
          >
            {type === DnDBoxTypes.Variable && onPlayground && (
              <div ref={drag2} className={iclasses.variableIcon}>
                <VariableIcon />
              </div>
            )}
            {(type === DnDBoxTypes.Tool || type === DnDBoxTypes.Result) &&
              onPlayground && (
                <div
                  ref={drag2}
                  className={clsx(iclasses.connector, iclasses.connectorLeft)}
                />
              )}
            <div
              ref={drag}
              className={clsx(
                iclasses.root,
                classes?.root,
                !onPlayground && iclasses.svgMargin,
              )}
            >
              {icon}
              {(((type === DnDBoxTypes.Tool || type === DnDBoxTypes.Result) &&
                !onPlayground) ||
                type === DnDBoxTypes.Variable) &&
                name}
            </div>
            {type === DnDBoxTypes.Tool && onPlayground && (
              <div
                ref={drag2}
                className={clsx(iclasses.connector, iclasses.connectorRight)}
              />
            )}
          </div>
        </div>

        {children}
      </div>
    );
  },
);

export default DnDBox;
