import _ from 'lodash';
import React, {useCallback, useEffect, useState} from 'react';
import {HTML5Backend} from 'react-dnd-html5-backend';

import {DndProvider, DropTargetMonitor} from 'react-dnd';
import DataViewContainer from '~/components/DataViewContainer';
import StatTable from '~/components/StatTable';
import FileDropBox from '~/components/FileDropBox';
import {Box, makeStyles, Tab, Tabs, Theme, Typography} from '@material-ui/core';
import Playground from '~/components/Playground';
import {useDispatch, useSelector} from 'react-redux';
import {RootState} from '~/features/rootReducer';
import {uploadRequest} from '~/features/UploadFile/slice';
import {IMetaTable, METATABLE} from '../features/MetaTable/slice';
import DataTable from '~/components/DataTable';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    width: 'calc(100vw - 148px)',
  },
  menu: {
    borderBottom: `1px solid ${theme.palette.grey['300']}`,
    padding: '6px 20px',
  },
  pane: {
    flex: 1,
    padding: 20,
    display: 'flex',
    flexDirection: 'column',
  },
  statPane: {
    width: '30%',
    marginRight: 20,
  },
  dataPane: {
    width: 'calc(70% - 20px)',
  },
  topPane: {display: 'flex', marginBottom: 20, height: '30%'},
  bottomPane: {
    width: '100%',
  },
  fileDialog: {
    width: '70vw',
    height: '50vh',
    padding: '24px 24px',
  },
}));

function a11yProps(index: any) {
  return {
    id: `scrollable-auto-tab-${index}`,
    'aria-controls': `scrollable-auto-tabpanel-${index}`,
  };
}

interface TabPanelProps {
  children?: React.ReactNode;
  index: any;
  value: any;
}

function TabPanel(props: TabPanelProps) {
  const {children, value, index, ...other} = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

const AnalyzeScreen = () => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const metaTables = useSelector(
    (state: RootState) => state[METATABLE].metaTables,
  );
  const [metaTable, setMetaTable] = useState<IMetaTable | undefined>();

  const [value, setValue] = React.useState(0);

  useEffect(() => {
    if (metaTables.length > 0 || !metaTable) {
      setMetaTable(metaTables[0]);
    }
  }, [metaTables, metaTable]);

  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setValue(newValue);
  };

  const handleFileDrop = useCallback(
    (item: any, monitor: DropTargetMonitor) => {
      if (monitor) {
        const files = monitor.getItem().files;
        dispatch(uploadRequest(files));
      }
    },
    [dispatch],
  );

  return (
    <DndProvider backend={HTML5Backend}>
      <div className={classes.pane}>
        <div className={classes.topPane}>
          <div className={classes.statPane}>
            <DataViewContainer center={!!!metaTable}>
              {metaTable && <StatTable meta={metaTable} />}
              {!metaTable && <Typography>No data to analyze</Typography>}
            </DataViewContainer>
          </div>
          <div className={classes.dataPane}>
            <DataViewContainer>
              {metaTables.length > 0 && (
                <>
                  <Tabs
                    value={value}
                    onChange={handleChange}
                    indicatorColor="primary"
                    textColor="primary"
                    variant="scrollable"
                    scrollButtons="auto"
                    aria-label="scrollable auto tabs example"
                  >
                    {_.map(metaTables, (meta, indx) => (
                      <Tab
                        key={`metaTables-${indx}`}
                        label={meta.filename}
                        {...a11yProps(indx)}
                      />
                    ))}
                  </Tabs>
                  {_.map(metaTables, (meta, indx) => (
                    <TabPanel
                      key={`table-pane-${indx}`}
                      value={value}
                      index={indx}
                    >
                      <DataTable meta={meta} />
                    </TabPanel>
                  ))}
                </>
              )}
              {!metaTable && <FileDropBox onDrop={handleFileDrop} />}
            </DataViewContainer>
          </div>
        </div>
        <div className={classes.bottomPane}>
          <DataViewContainer backStyle>
            <Playground />
          </DataViewContainer>
        </div>
      </div>
    </DndProvider>
  );
};

export default AnalyzeScreen;
