import axios from 'axios';

export const getMetaTables = (ids: number[]) => {
  const filter = {
    where: {
      tableId: {
        inq: ids,
      },
    },
  };
  return axios.get(`/meta-tables?filter=${JSON.stringify(filter)}`);
};

export const getResult = (eq: any) => {
  return axios.post('/get-result', eq);
};
