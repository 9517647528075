import {PayloadAction} from '@reduxjs/toolkit';
import {call, put, takeLatest} from 'redux-saga/effects';
import {getData} from '~/api';
import {load, loadSuccess} from './slice';

function* handleLoadData({payload}: PayloadAction<string>) {
  try {
    const res = yield call(getData, payload);
    yield put(loadSuccess({name: payload, data: res.data}));
  } catch (err) {}
}

export function* watchData() {
  yield takeLatest(load, handleLoadData);
}
