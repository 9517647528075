import {PayloadAction} from '@reduxjs/toolkit';
import {call, put, takeLatest} from 'redux-saga/effects';
import {uploadFile} from '~/api';
import * as MetaTableAction from '../MetaTable/slice';
import {uploadRequest} from './slice';

function* handleUploadRequest({payload}: PayloadAction<File[]>) {
  try {
    const res = yield call(uploadFile, payload);
    yield put(MetaTableAction.append([res.data]));
  } catch (err) {}
}

export function* watchUploadFile() {
  yield takeLatest(uploadRequest, handleUploadRequest);
}
