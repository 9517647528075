import { Button as MuiButton, PropTypes, withStyles } from "@material-ui/core";
import React from "react";

export interface IconButtonProps {
  /**
   * The color of the component. It supports those theme colors that make sense for this component.
   */
  color?: PropTypes.Color;
  /**
   * Icon element.
   */
  icon: React.ReactNode;
  /**
   * The size of the button.
   * `small` is equivalent to the dense button styling.
   */
  size?: "small" | "medium" | "large";
  /**
   * The variant to use.
   */
  variant?: "text" | "outlined" | "contained";
  /**
   * Optional click handler
   */
  onClick?: () => void;
}

const StyledButton = withStyles((theme) => ({
  root: {
    borderRadius: 0,
    padding: 0,
    minWidth: 48,
    minHeight: 48,
    "&:hover": {
      boxShadow: "0 0 0 0",
    },
    "&:active": {
      boxShadow: "0 0 0 0",
    },
  },
  startIcon: {
    margin: 0,
  },
  iconSizeMedium: {
    padding: 8,
  },
  contained: {
    boxShadow: "0 0 0 0",
  },
}))(MuiButton);

/**
 * Primary UI component for user interaction
 */
export const IconButton: React.FC<IconButtonProps> = ({ icon, ...props }) => {
  return <StyledButton disableTouchRipple={true} startIcon={icon} {...props} />;
};
