import React from 'react';
import {Paper, Typography, withStyles} from '@material-ui/core';

export interface FileBoxIconItem {
  icon: React.ReactNode;
  iconTitle: string;
  backgroundColor: string;
}

const StyledPaper = withStyles(theme => ({
  root: {
    width: 100,
    height: 100,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    color: theme.palette.common.white,
  },
}))(Paper);

const FileBoxIcon: React.FC<FileBoxIconItem> = props => {
  const {icon, iconTitle, backgroundColor} = props;
  return (
    <StyledPaper style={{backgroundColor}} elevation={0} square>
      {icon}
      <Typography>{iconTitle}</Typography>
    </StyledPaper>
  );
};
export default FileBoxIcon;
