import React from 'react';

const ImageResult = () => {
  return (
    <svg
      width="36"
      height="36"
      viewBox="0 0 25 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0)">
        <path
          d="M2.83317 4.04199C2.83317 3.37741 3.37692 2.83366 4.0415 2.83366H10.0832C10.7478 2.83366 11.2915 2.28991 11.2915 1.62533C11.2915 0.960742 10.7478 0.416992 10.0832 0.416992H2.83317C1.504 0.416992 0.416504 1.50449 0.416504 2.83366V10.0837C0.416504 10.7482 0.960254 11.292 1.62484 11.292C2.28942 11.292 2.83317 10.7482 2.83317 10.0837V4.04199ZM9.61192 14.3007L6.03525 18.7716C5.72109 19.1703 5.999 19.7503 6.5065 19.7503H18.5415C19.0369 19.7503 19.3269 19.1824 19.0248 18.7837L16.6082 15.5574C16.3665 15.2312 15.8832 15.2312 15.6415 15.5574L13.6719 18.1916L10.5544 14.3007C10.3128 13.9987 9.85359 13.9987 9.61192 14.3007ZM18.5415 8.27116C18.5415 7.26824 17.7319 6.45866 16.729 6.45866C15.7261 6.45866 14.9165 7.26824 14.9165 8.27116C14.9165 9.27408 15.7261 10.0837 16.729 10.0837C17.7319 10.0837 18.5415 9.27408 18.5415 8.27116ZM22.1665 0.416992H14.9165C14.2519 0.416992 13.7082 0.960742 13.7082 1.62533C13.7082 2.28991 14.2519 2.83366 14.9165 2.83366H20.9582C21.6228 2.83366 22.1665 3.37741 22.1665 4.04199V10.0837C22.1665 10.7482 22.7103 11.292 23.3748 11.292C24.0394 11.292 24.5832 10.7482 24.5832 10.0837V2.83366C24.5832 1.50449 23.4957 0.416992 22.1665 0.416992ZM22.1665 20.9587C22.1665 21.6232 21.6228 22.167 20.9582 22.167H14.9165C14.2519 22.167 13.7082 22.7107 13.7082 23.3753C13.7082 24.0399 14.2519 24.5837 14.9165 24.5837H22.1665C23.4957 24.5837 24.5832 23.4962 24.5832 22.167V14.917C24.5832 14.2524 24.0394 13.7087 23.3748 13.7087C22.7103 13.7087 22.1665 14.2524 22.1665 14.917V20.9587ZM1.62484 13.7087C0.960254 13.7087 0.416504 14.2524 0.416504 14.917V22.167C0.416504 23.4962 1.504 24.5837 2.83317 24.5837H10.0832C10.7478 24.5837 11.2915 24.0399 11.2915 23.3753C11.2915 22.7107 10.7478 22.167 10.0832 22.167H4.0415C3.37692 22.167 2.83317 21.6232 2.83317 20.9587V14.917C2.83317 14.2524 2.28942 13.7087 1.62484 13.7087Z"
          fill="#4F4F4F"
        />
      </g>
      <defs>
        <clipPath id="clip0">
          <rect width="25" height="25" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default ImageResult;
