import React from 'react';

const Binning = () => {
  return (
    <svg
      width="36"
      height="36"
      viewBox="0 0 25 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0)">
        <path
          d="M0.125 0.125V8.375H2.875V2.875H8.375V0.125H0.125ZM2.875 16.625H0.125V24.875H8.375V22.125H2.875V16.625ZM22.125 22.125H16.625V24.875H24.875V16.625H22.125V22.125ZM24.875 0.125H16.625V2.875H22.125V8.375H24.875V0.125Z"
          fill="black"
          fillOpacity="0.54"
        />
        <rect
          x="18.6611"
          y="6.45117"
          width="11.9186"
          height="12.2093"
          transform="rotate(90 18.6611 6.45117)"
          fill="#D63B57"
        />
        <g filter="url(#filter0_d)">
          <path
            d="M15.725 15.8711H14.2165L13.7794 14.504H11.5938L11.161 15.8711H9.66111L11.8981 9.7258H13.5394L15.725 15.8711ZM13.4623 13.4413L12.8023 11.3757C12.7538 11.2214 12.7195 11.0371 12.6995 10.8229H12.6652C12.6509 11.0029 12.6152 11.1814 12.5581 11.3585L11.8895 13.4413H13.4623Z"
            fill="white"
          />
        </g>
      </g>
      <defs>
        <filter
          id="filter0_d"
          x="2.94895"
          y="5.69828"
          width="16.8034"
          height="16.8848"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          />
          <feOffset dx="-1.34244" dy="1.34244" />
          <feGaussianBlur stdDeviation="2.68487" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow"
            result="shape"
          />
        </filter>
        <clipPath id="clip0">
          <rect width="25" height="25" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default Binning;
