import React from 'react';

const Missing = () => {
  return (
    <svg
      width="36"
      height="36"
      viewBox="0 0 25 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0)">
        <rect
          x="0.5"
          y="4.61719"
          width="5.2389"
          height="5.2389"
          fill="#757575"
          stroke="#757575"
        />
        <rect
          x="7.98682"
          y="4.61719"
          width="5.2389"
          height="5.2389"
          fill="#757575"
          stroke="#757575"
        />
        <rect
          x="7.98682"
          y="12.1035"
          width="5.2389"
          height="5.2389"
          fill="#757575"
          stroke="#757575"
        />
        <rect
          x="0.5"
          y="12.1035"
          width="5.2389"
          height="5.2389"
          fill="#757575"
          stroke="#757575"
        />
        <rect
          x="0.5"
          y="19.5908"
          width="5.2389"
          height="5.2389"
          fill="#757575"
          stroke="#757575"
        />
        <rect
          x="7.98682"
          y="19.5908"
          width="5.2389"
          height="5.2389"
          fill="#757575"
          stroke="#757575"
        />
        <rect
          x="15.4731"
          y="19.5908"
          width="5.2389"
          height="5.2389"
          fill="#757575"
          stroke="#757575"
        />
        <rect
          x="15.4731"
          y="12.1035"
          width="5.2389"
          height="5.2389"
          fill="#757575"
          stroke="#757575"
        />
        <g filter="url(#filter0_d)">
          <path
            d="M16.2212 7.43262H17.5221V8.7335H16.2212V7.43262Z"
            fill="#2196F3"
          />
        </g>
        <g filter="url(#filter1_d)">
          <path
            d="M16.2212 5.5752H17.5221V6.87607H16.2212V5.5752Z"
            fill="#2196F3"
          />
        </g>
        <g filter="url(#filter2_d)">
          <path
            d="M16.2212 3.71777H17.5221V5.01865H16.2212V3.71777Z"
            fill="#2196F3"
          />
        </g>
        <g filter="url(#filter3_d)">
          <path
            d="M16.2212 1.8584H17.5221V3.15928H16.2212V1.8584Z"
            fill="#2196F3"
          />
        </g>
        <g filter="url(#filter4_d)">
          <path d="M16.2217 0H17.5226V1.30088H16.2217V0Z" fill="#2196F3" />
        </g>
        <g filter="url(#filter5_d)">
          <path
            d="M18.0801 7.43262H19.381V8.7335H18.0801V7.43262Z"
            fill="#2196F3"
          />
        </g>
        <path d="M18.0801 0H19.381V1.30088H18.0801V0Z" fill="#2196F3" />
        <g filter="url(#filter6_d)">
          <path
            d="M19.9375 7.43262H21.2384V8.7335H19.9375V7.43262Z"
            fill="#2196F3"
          />
        </g>
        <path d="M19.9375 0H21.2384V1.30088H19.9375V0Z" fill="#2196F3" />
        <g filter="url(#filter7_d)">
          <path
            d="M21.7954 7.43262H23.0963V8.7335H21.7954V7.43262Z"
            fill="#2196F3"
          />
        </g>
        <path d="M21.7954 0H23.0963V1.30088H21.7954V0Z" fill="#2196F3" />
        <g filter="url(#filter8_d)">
          <path
            d="M23.6548 7.43262H24.9557V8.7335H23.6548V7.43262Z"
            fill="#2196F3"
          />
        </g>
        <path
          d="M23.6548 5.5752H24.9557V6.87607H23.6548V5.5752Z"
          fill="#2196F3"
        />
        <path
          d="M23.6548 3.71777H24.9557V5.01865H23.6548V3.71777Z"
          fill="#2196F3"
        />
        <path
          d="M23.6548 1.8584H24.9557V3.15928H23.6548V1.8584Z"
          fill="#2196F3"
        />
        <path d="M23.6548 0H24.9557V1.30088H23.6548V0Z" fill="#2196F3" />
      </g>
      <defs>
        <filter
          id="filter0_d"
          x="14.2212"
          y="7.43262"
          width="3.30088"
          height="3.30088"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          />
          <feOffset dx="-1" dy="1" />
          <feGaussianBlur stdDeviation="0.5" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.4 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow"
            result="shape"
          />
        </filter>
        <filter
          id="filter1_d"
          x="14.2212"
          y="5.5752"
          width="3.30088"
          height="3.30088"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          />
          <feOffset dx="-1" dy="1" />
          <feGaussianBlur stdDeviation="0.5" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.4 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow"
            result="shape"
          />
        </filter>
        <filter
          id="filter2_d"
          x="14.2212"
          y="3.71777"
          width="3.30088"
          height="3.30088"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          />
          <feOffset dx="-1" dy="1" />
          <feGaussianBlur stdDeviation="0.5" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.4 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow"
            result="shape"
          />
        </filter>
        <filter
          id="filter3_d"
          x="14.2212"
          y="1.8584"
          width="3.30088"
          height="3.30088"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          />
          <feOffset dx="-1" dy="1" />
          <feGaussianBlur stdDeviation="0.5" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.4 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow"
            result="shape"
          />
        </filter>
        <filter
          id="filter4_d"
          x="14.2217"
          y="0"
          width="3.30088"
          height="3.30088"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          />
          <feOffset dx="-1" dy="1" />
          <feGaussianBlur stdDeviation="0.5" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.4 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow"
            result="shape"
          />
        </filter>
        <filter
          id="filter5_d"
          x="16.0801"
          y="7.43262"
          width="3.30088"
          height="3.30088"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          />
          <feOffset dx="-1" dy="1" />
          <feGaussianBlur stdDeviation="0.5" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.4 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow"
            result="shape"
          />
        </filter>
        <filter
          id="filter6_d"
          x="17.9375"
          y="7.43262"
          width="3.30088"
          height="3.30088"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          />
          <feOffset dx="-1" dy="1" />
          <feGaussianBlur stdDeviation="0.5" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.4 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow"
            result="shape"
          />
        </filter>
        <filter
          id="filter7_d"
          x="19.7954"
          y="7.43262"
          width="3.30088"
          height="3.30088"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          />
          <feOffset dx="-1" dy="1" />
          <feGaussianBlur stdDeviation="0.5" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.4 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow"
            result="shape"
          />
        </filter>
        <filter
          id="filter8_d"
          x="21.6548"
          y="7.43262"
          width="3.30088"
          height="3.30088"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          />
          <feOffset dx="-1" dy="1" />
          <feGaussianBlur stdDeviation="0.5" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.4 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow"
            result="shape"
          />
        </filter>
        <clipPath id="clip0">
          <rect width="25" height="25" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default Missing;
