import React from 'react';

const Regression = () => {
  return (
    <svg
      width="36"
      height="36"
      viewBox="0 0 25 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.1463 2C8.2874 2 3.53659 6.73432 3.53659 12.5729H0L4.58577 17.1428L4.66829 17.3072L9.43089 12.5729H5.89431C5.89431 8.02656 9.58415 4.34954 14.1463 4.34954C18.7085 4.34954 22.3984 8.02656 22.3984 12.5729C22.3984 17.1193 18.7085 20.7963 14.1463 20.7963C11.8711 20.7963 9.80813 19.8682 8.32277 18.3763L6.64878 20.0444C8.57033 21.9593 11.211 23.1458 14.1463 23.1458C20.0053 23.1458 24.7561 18.4115 24.7561 12.5729C24.7561 6.73432 20.0053 2 14.1463 2Z"
        fill="#605E5C"
      />
      <g filter="url(#filter0_d)">
        <path
          d="M20.6295 12.8306C20.6295 16.2487 17.8586 19.0197 14.4405 19.0197C11.0224 19.0197 8.25146 16.2487 8.25146 12.8306C8.25146 9.41252 11.0224 6.6416 14.4405 6.6416C17.8586 6.6416 20.6295 9.41252 20.6295 12.8306Z"
          fill="#BB4DEE"
        />
      </g>
      <path
        d="M16.7758 14.7666H16.0935L15.536 13.292H13.3058L12.7813 14.7666H12.0953L14.1128 9.5066H14.751L16.7758 14.7666ZM15.3342 12.7382L14.5089 10.497C14.482 10.4236 14.4551 10.3062 14.4282 10.1448H14.4136C14.3891 10.294 14.361 10.4114 14.3292 10.497L13.5112 12.7382H15.3342Z"
        fill="white"
      />
      <defs>
        <filter
          id="filter0_d"
          x="7.17829"
          y="5.56843"
          width="16.6707"
          height="16.6707"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          />
          <feOffset dx="1.07317" dy="1.07317" />
          <feGaussianBlur stdDeviation="1.07317" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  );
};

export default Regression;
