import React from 'react';
import {NativeTypes} from 'react-dnd-html5-backend';
import {useDrop, DropTargetMonitor} from 'react-dnd';
import FileBoxIcon from './FileBoxIcon';
import {
  Grid,
  makeStyles,
  Theme,
  Typography,
  withStyles,
} from '@material-ui/core';
import CloudUploadOutlinedIcon from '@material-ui/icons/CloudUploadOutlined';
import AssignmentOutlinedIcon from '@material-ui/icons/AssignmentOutlined';
import clsx from 'clsx';

export interface FileDropBoxProps {
  onDrop: (props: FileDropBoxProps, monitor: DropTargetMonitor) => void;
}

const useStyles = makeStyles((theme: Theme) => ({
  active: {
    backgroundColor: theme.palette.primary.light,
  },
}));

const StyledGrid = withStyles(theme => ({
  root: {
    textAlign: 'center',
  },
}))(Grid);

const StyledGrid2 = withStyles(theme => ({
  root: {
    margin: theme.spacing(4.5),
  },
}))(Grid);

const StyledTypography = withStyles(theme => ({
  root: {
    marginTop: theme.spacing(1),
  },
}))(Typography);

const FileDropBox: React.FC<FileDropBoxProps> = props => {
  const classes = useStyles();

  const {onDrop} = props;
  const [{canDrop, isOver}, drop] = useDrop({
    accept: [NativeTypes.FILE],
    drop: (item, monitor) => {
      if (onDrop) {
        onDrop(props, monitor);
      }
    },
    collect: monitor => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
    }),
  });

  const isActive = canDrop && isOver;
  return (
    <div ref={drop} className={clsx(isActive && classes.active)}>
      <StyledGrid container direction="column">
        <Grid container justify="center" direction="column">
          <Grid>
            <CloudUploadOutlinedIcon
              style={{fontSize: '9rem'}}
              color="action"
            />
          </Grid>
          <Grid>
            <StyledTypography color="textSecondary" variant="h4">
              Drag & Drop a File
            </StyledTypography>
          </Grid>
          <Grid>
            <StyledTypography color="textSecondary" variant="h5">
              below file formats are accepted
            </StyledTypography>
          </Grid>
        </Grid>

        <Grid container justify="center">
          {/* <StyledGrid2>
            <FileBoxIcon
              backgroundColor={'#107C10'}
              icon={<DescriptionOutlinedIcon fontSize="large" />}
              iconTitle={'Excel'}
            />
          </StyledGrid2> */}
          <StyledGrid2>
            <FileBoxIcon
              backgroundColor={'#A4262C'}
              icon={<AssignmentOutlinedIcon fontSize="large" />}
              iconTitle={'SPSS'}
            />
          </StyledGrid2>
          {/* <StyledGrid2>
            <FileBoxIcon
              backgroundColor={'#0078D4'}
              icon={<NoteAddOutlinedIcon fontSize="large" />}
              iconTitle={'SAS'}
            />
          </StyledGrid2> */}
        </Grid>
      </StyledGrid>
    </div>
  );
};

export default FileDropBox;
