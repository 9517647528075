import {createSlice, PayloadAction} from '@reduxjs/toolkit';

interface IState {
  loading: boolean;
  result: any;
}

let initialState: IState = {
  loading: false,
  result: undefined,
};

const slice = createSlice({
  name: 'result',
  initialState,
  reducers: {
    getResult: (state, _action: PayloadAction<any>) => {
      state.loading = true;
    },
    successGetResult: (state, {payload}: PayloadAction<any>) => {
      state.loading = false;
      state.result = payload;
    },
  },
});

export const {getResult, successGetResult} = slice.actions;
export const RESULT = slice.name;
export default slice.reducer;
