import React, {useCallback} from 'react';
import {Typography, withStyles} from '@material-ui/core';
import Button from '@material-ui/core/Button';
import {useHistory} from 'react-router-dom';

export interface INavButtonItem {
  icon: React.ReactNode;
  title: string;
  linkto: string;
  disabled: boolean;
  active: boolean;
}

const StyledButton = withStyles(theme => ({
  root: {
    margin: 8,
    '&:hover': {
      backgroundColor: theme.palette.common.white,
    },
    '&:active': {
      color: theme.palette.primary.dark,
    },
  },
}))(Button);

const StyledTypography = withStyles(theme => ({
  root: {
    '&:active': {
      color: theme.palette.common.black,
    },
  },
}))(Typography);

const NavButton: React.FC<INavButtonItem> = props => {
  const history = useHistory();

  const {icon, title, disabled, active, linkto} = props;
  const [isHover, setIsHover] = React.useState(false);
  const handleHover = () => {
    setIsHover(true);
  };
  const handleOut = () => {
    setIsHover(false);
  };
  const getTextColor = useCallback(() => {
    if (disabled) {
      return 'textSecondary';
    } else if (active || isHover) {
      return 'primary';
    } else {
      return 'textPrimary';
    }
  }, [disabled, isHover, active]);

  const handleClick = () => {
    history.push(linkto);
  };

  return (
    <div>
      <StyledButton
        disableRipple
        disabled={disabled}
        aria-label="show 4 new mails"
        color={isHover || active ? 'primary' : 'default'}
        onMouseOver={handleHover}
        onMouseOut={handleOut}
        startIcon={icon}
        onClick={handleClick}
      >
        <StyledTypography variant="subtitle2" color={getTextColor()}>
          {title}
        </StyledTypography>
      </StyledButton>
    </div>
  );
};
export default NavButton;
