import {configureStore} from '@reduxjs/toolkit';
import {all} from 'redux-saga/effects';
import createSagaMiddleware from 'redux-saga';

import rootReducer from './rootReducer';
import {watchUploadFile} from './UploadFile/saga';
import {watchData} from './Data/saga';
import {watchTable} from './Table/saga';
import {watchMetaTable} from './MetaTable/saga';
import {watchResult} from './Result/saga';

function* rootSaga() {
  yield all([
    watchUploadFile(),
    watchData(),
    watchTable(),
    watchMetaTable(),
    watchResult(),
  ]);
}

const createStore = () => {
  const sagaMiddleware = createSagaMiddleware({});

  const store = configureStore({
    reducer: rootReducer,
    devTools: true,
    middleware: [sagaMiddleware],
  });

  sagaMiddleware.run(rootSaga);
  return store;
};

export type AppDispatch = ReturnType<typeof createStore>;
export default createStore;
