import React from 'react';

const Std = () => {
  return (
    <svg
      width="36"
      height="36"
      viewBox="0 0 25 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect x="1" y="15.8828" width="3" height="8.11765" fill="#605E5C" />
      <rect x="5" y="10.4707" width="3" height="13.5294" fill="#605E5C" />
      <rect x="17" y="10.4707" width="3" height="13.5294" fill="#605E5C" />
      <rect x="21" y="15.8828" width="3" height="8.11765" fill="#605E5C" />
      <rect x="9" y="1" width="3" height="23" fill="#220BAC" />
      <rect x="13" y="1" width="3" height="23" fill="#220BAC" />
      <path
        d="M11.6991 14.7646H11.3359L11.039 13.9795H9.85148L9.57218 14.7646H9.20695L10.2812 11.9639H10.621L11.6991 14.7646ZM10.9316 13.6846L10.4921 12.4912C10.4778 12.4521 10.4635 12.3896 10.4491 12.3037H10.4413C10.4283 12.3831 10.4133 12.4456 10.3964 12.4912L9.96086 13.6846H10.9316Z"
        fill="white"
      />
      <path
        d="M13.6757 14.7646V11.9639H14.4726C14.7148 11.9639 14.9068 12.0231 15.0487 12.1416C15.1907 12.2601 15.2616 12.4144 15.2616 12.6045C15.2616 12.7633 15.2187 12.9014 15.1327 13.0186C15.0468 13.1357 14.9283 13.2191 14.7773 13.2686V13.2764C14.9661 13.2985 15.1171 13.3701 15.2304 13.4912C15.3437 13.611 15.4003 13.7673 15.4003 13.96C15.4003 14.1995 15.3144 14.3936 15.1425 14.542C14.9706 14.6904 14.7538 14.7646 14.4921 14.7646H13.6757ZM14.0038 12.2607V13.165H14.3398C14.5194 13.165 14.6607 13.1221 14.7636 13.0361C14.8665 12.9489 14.9179 12.8265 14.9179 12.6689C14.9179 12.3968 14.7388 12.2607 14.3808 12.2607H14.0038ZM14.0038 13.46V14.4678H14.4491C14.6418 14.4678 14.7909 14.4222 14.8964 14.3311C15.0032 14.2399 15.0566 14.1149 15.0566 13.9561C15.0566 13.6253 14.8313 13.46 14.3808 13.46H14.0038Z"
        fill="white"
      />
    </svg>
  );
};

export default Std;
