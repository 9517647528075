import axios from "axios";

export const uploadFile = (files: File[]) => {
  let formData = new FormData();
  for (let i = 0; i < files.length; i++) {
    formData.append(`files[${i}]`, files[i]);
  }

  return axios.post(`/files`, formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};
