import React from 'react';

const Correlation = () => {
  return (
    <svg
      width="36"
      height="36"
      viewBox="0 0 25 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0)">
        <path
          d="M10.5612 8.60875L3.43875 1.5L1.5 3.43875L8.60875 10.5475L10.5612 8.60875ZM15.9375 1.5L18.7425 4.305L1.5 21.5612L3.43875 23.5L20.695 6.2575L23.5 9.0625V1.5H15.9375ZM16.3912 14.4387L14.4525 16.3775L18.7562 20.6812L15.9375 23.5H23.5V15.9375L20.695 18.7425L16.3912 14.4387Z"
          fill="#605E5C"
        />
        <ellipse
          cx="5.39795"
          cy="12.582"
          rx="4.5"
          ry="4.49993"
          fill="#EE4D87"
        />
        <path
          d="M8.18355 15.082H7.45699L6.86324 13.5117H4.48824L3.92964 15.082H3.19917L5.34761 9.48047H6.0273L8.18355 15.082ZM6.64839 12.9219L5.76949 10.5352C5.74084 10.457 5.71219 10.332 5.68355 10.1602H5.66792C5.64188 10.319 5.61193 10.444 5.57808 10.5352L4.70699 12.9219H6.64839Z"
          fill="white"
        />
        <circle cx="20.6636" cy="12.582" r="4.5" fill="#EE4D87" />
        <path
          d="M18.8424 14.9795V9.37793H20.4361C20.9205 9.37793 21.3046 9.49642 21.5884 9.7334C21.8723 9.97038 22.0142 10.279 22.0142 10.6592C22.0142 10.9769 21.9283 11.2529 21.7564 11.4873C21.5845 11.7217 21.3476 11.8883 21.0455 11.9873V12.0029C21.4231 12.0472 21.7252 12.1904 21.9517 12.4326C22.1783 12.6722 22.2916 12.9847 22.2916 13.3701C22.2916 13.8493 22.1197 14.2373 21.7759 14.5342C21.4322 14.8311 20.9986 14.9795 20.4752 14.9795H18.8424ZM19.4986 9.97168V11.7803H20.1705C20.5299 11.7803 20.8124 11.6943 21.0181 11.5225C21.2239 11.348 21.3267 11.1032 21.3267 10.7881C21.3267 10.2438 20.9687 9.97168 20.2525 9.97168H19.4986ZM19.4986 12.3701V14.3857H20.3892C20.7746 14.3857 21.0728 14.2946 21.2838 14.1123C21.4973 13.93 21.6041 13.68 21.6041 13.3623C21.6041 12.7008 21.1536 12.3701 20.2525 12.3701H19.4986Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0">
          <rect width="25" height="25" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default Correlation;
