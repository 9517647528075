import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import update from 'immutability-helper';

interface IDatum {
  [key: string]: number | string | null;
}

export interface IData {
  name: string;
  data: IDatum[];
}
// export type IData = IDatum[];

interface IState {
  loading: boolean;
  data: IData[];
}

let initialState: IState = {
  loading: false,
  data: [],
};

const slice = createSlice({
  name: 'data',
  initialState,
  reducers: {
    load: (state, _action: PayloadAction<string>) => {
      state.loading = true;
    },
    loadSuccess: (state, action: PayloadAction<IData>) => {
      state.loading = false;
      state.data = update(state.data, {$push: [action.payload]});
    },
  },
});

export const {load, loadSuccess} = slice.actions;
export const DATA = slice.name;
export default slice.reducer;
