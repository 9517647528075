import {makeStyles, Theme} from '@material-ui/core/styles';
import React from 'react';

const useStyles = makeStyles((theme: Theme) => ({
  root: {height: '100%', border: 0},
}));

const MapScreen = () => {
  const classes = useStyles();
  return <iframe className={classes.root} src="https://demo.thingsw.com/" />;
};

export default MapScreen;
