import {combineReducers} from '@reduxjs/toolkit';
import dataReducer, {DATA} from './Data/slice';
import metaTableReducer, {METATABLE} from './MetaTable/slice';
import resultReducer, {RESULT} from './Result/slice';
import tableReducer, {TABLE} from './Table/slice';
import fileUploadReducer, {UPLOADFILE} from './UploadFile/slice';
import {routerReducer} from 'react-router-redux';

const rootReducer = combineReducers({
  [UPLOADFILE]: fileUploadReducer,
  [DATA]: dataReducer,
  [TABLE]: tableReducer,
  [METATABLE]: metaTableReducer,
  [RESULT]: resultReducer,
  routing: routerReducer,
});

export type RootState = ReturnType<typeof rootReducer>;
export default rootReducer;
