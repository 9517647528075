import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@material-ui/core';
import React from 'react';
import _ from 'lodash';
import {IMetaTable} from '~/features/MetaTable/slice';

export interface IDataTableProps {
  /**
   * table meta data
   */
  meta: IMetaTable;
}

const DataTable: React.FC<IDataTableProps> = props => {
  const {
    meta: {headers, stat},
  } = props;

  return (
    <TableContainer style={{maxHeight: 480}}>
      <Table stickyHeader>
        <TableHead>
          <TableRow>
            <TableCell>변수</TableCell>
            <TableCell>평균(mean)</TableCell>
            <TableCell>최대값</TableCell>
            <TableCell>최소값</TableCell>
            <TableCell>표준편차</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {_.map(stat, s => (
            <TableRow key={`row_${s.var}`}>
              <TableCell>
                {_.find(headers, h => h.colName === s.var)?.var}
              </TableCell>
              <TableCell>{s.mean}</TableCell>
              <TableCell>{s.max}</TableCell>
              <TableCell>{s.min}</TableCell>
              <TableCell>{s.std}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default DataTable;
