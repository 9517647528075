import React from 'react';

const DefaultResult = () => {
  return (
    <svg
      width="36"
      height="36"
      viewBox="0 0 25 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0)">
        <path
          d="M1.66667 15.833H4.5C5.27917 15.833 5.91667 15.1955 5.91667 14.4163V11.583C5.91667 10.8038 5.27917 10.1663 4.5 10.1663H1.66667C0.8875 10.1663 0.25 10.8038 0.25 11.583V14.4163C0.25 15.1955 0.8875 15.833 1.66667 15.833ZM1.66667 22.9163H4.5C5.27917 22.9163 5.91667 22.2788 5.91667 21.4997V18.6663C5.91667 17.8872 5.27917 17.2497 4.5 17.2497H1.66667C0.8875 17.2497 0.25 17.8872 0.25 18.6663V21.4997C0.25 22.2788 0.8875 22.9163 1.66667 22.9163ZM1.66667 8.74968H4.5C5.27917 8.74968 5.91667 8.11217 5.91667 7.33301V4.49967C5.91667 3.72051 5.27917 3.08301 4.5 3.08301H1.66667C0.8875 3.08301 0.25 3.72051 0.25 4.49967V7.33301C0.25 8.11217 0.8875 8.74968 1.66667 8.74968ZM8.75 15.833H22.9167C23.6958 15.833 24.3333 15.1955 24.3333 14.4163V11.583C24.3333 10.8038 23.6958 10.1663 22.9167 10.1663H8.75C7.97083 10.1663 7.33333 10.8038 7.33333 11.583V14.4163C7.33333 15.1955 7.97083 15.833 8.75 15.833ZM8.75 22.9163H22.9167C23.6958 22.9163 24.3333 22.2788 24.3333 21.4997V18.6663C24.3333 17.8872 23.6958 17.2497 22.9167 17.2497H8.75C7.97083 17.2497 7.33333 17.8872 7.33333 18.6663V21.4997C7.33333 22.2788 7.97083 22.9163 8.75 22.9163ZM7.33333 4.49967V7.33301C7.33333 8.11217 7.97083 8.74968 8.75 8.74968H22.9167C23.6958 8.74968 24.3333 8.11217 24.3333 7.33301V4.49967C24.3333 3.72051 23.6958 3.08301 22.9167 3.08301H8.75C7.97083 3.08301 7.33333 3.72051 7.33333 4.49967Z"
          fill="#4F4F4F"
        />
      </g>
      <defs>
        <clipPath id="clip0">
          <rect width="25" height="25" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default DefaultResult;
