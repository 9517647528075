import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import update from 'immutability-helper';
import _ from 'lodash';

interface ITableHeader {
  var: string;
  c: string;
  colName: string;
}

interface IDefaultStat {
  var: string;
  mean: number;
  max: number;
  min: number;
  std: number;
}

export interface IMetaTable {
  /**
   * 아이디 primary key
   */
  id: number;

  /**
   * 데이터가 저장되어 있는 테이블 이름
   */
  tablename: string;

  /**
   * 업로드 파일명
   */
  filename: string;

  /**
   * 업로드 파일 타입
   */
  filetype: string;

  /**
   *
   */
  stat: IDefaultStat[];

  /**
   * 테이블 헤더
   */
  headers: ITableHeader[];
}

interface IState {
  loading: boolean;
  metaTables: IMetaTable[];
}

let initialState: IState = {
  loading: false,
  metaTables: [],
};

const slice = createSlice({
  name: 'meta-table',
  initialState,
  reducers: {
    load: (state, _action: PayloadAction<number[]>) => {
      state.loading = true;
    },
    append: (state, {payload}: PayloadAction<IMetaTable[]>) => {
      state.loading = false;
      state.metaTables = _.uniqBy(
        update(state.metaTables, {$push: payload}),
        'id',
      );
    },
    successLoad: (state, {payload}: PayloadAction<IMetaTable[]>) => {
      state.loading = false;
    },
  },
});

export const {load, append} = slice.actions;
export const METATABLE = slice.name;
export default slice.reducer;
