import {createMuiTheme} from '@material-ui/core/styles';

const theme = createMuiTheme({
  palette: {
    primary: {
      dark: '#005a9e',
      main: '#0078d4',
      light: '#c7e0f4',
      contrastText: '#ffffff',
    },
  },
});

export default theme;
