import {PayloadAction} from '@reduxjs/toolkit';
import {call, put, takeLatest} from 'redux-saga/effects';
import {push} from 'react-router-redux';
import * as api from '~/api/MetaTable';
import {getResult, successGetResult} from './slice';

function* handleGetResult({payload}: PayloadAction<any>) {
  try {
    const res = yield call(api.getResult, payload);
    yield put(successGetResult(res.data));
    yield put(push('/result'));
  } catch (err) {}
}

export function* watchResult() {
  yield takeLatest(getResult, handleGetResult);
}
