import React from 'react';

const Mean = () => {
  return (
    <svg
      width="36"
      height="36"
      viewBox="0 0 25 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect x="1" y="1" width="6.57143" height="17.5237" fill="#605E5C" />
      <rect
        x="17.4287"
        y="6.47656"
        width="6.57143"
        height="17.5237"
        fill="#605E5C"
      />
      <rect
        x="8.66699"
        y="4.28516"
        width="7.66667"
        height="17.5237"
        fill="#0BAC5F"
      />
      <path
        d="M14.5596 15.666H13.872L13.3102 14.1801H11.0628L10.5343 15.666H9.84304L11.876 10.3655H12.5192L14.5596 15.666ZM13.1069 13.6219L12.2752 11.3635C12.2481 11.2896 12.221 11.1713 12.1939 11.0086H12.1791C12.1545 11.1589 12.1261 11.2772 12.0941 11.3635L11.2698 13.6219H13.1069Z"
        fill="white"
      />
    </svg>
  );
};

export default Mean;
