import React from 'react';

const Abnormality = () => {
  return (
    <svg width="36" height="36" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M8.7625 15.5018H0V18.0992H8.7625V21.9953L13.75 16.8005L8.7625 11.6057V15.5018ZM16.2375 14.2031V10.307H25V7.70958H16.2375V3.81348L11.25 9.00828L16.2375 14.2031Z" fill="#696969"/>
    <ellipse cx="20.181" cy="19.8607" rx="4.27184" ry="4.42219" fill="#EE9A4D"/>
    <path d="M19.1807 21.4385V16.8608H20.4831C20.8789 16.8608 21.1928 16.9576 21.4248 17.1513C21.6568 17.3449 21.7728 17.5971 21.7728 17.9078C21.7728 18.1675 21.7025 18.3931 21.5621 18.5846C21.4216 18.7761 21.228 18.9123 20.9811 18.9932V19.006C21.2897 19.0421 21.5365 19.1592 21.7217 19.3571C21.9069 19.5529 21.9994 19.8083 21.9994 20.1233C21.9994 20.5148 21.859 20.8319 21.578 21.0746C21.2971 21.3172 20.9428 21.4385 20.515 21.4385H19.1807ZM19.717 17.346V18.824H20.266C20.5597 18.824 20.7906 18.7538 20.9587 18.6133C21.1269 18.4707 21.2109 18.2707 21.2109 18.0132C21.2109 17.5684 20.9183 17.346 20.3331 17.346H19.717ZM19.717 19.306V20.9533H20.4448C20.7598 20.9533 21.0034 20.8788 21.1758 20.7298C21.3503 20.5808 21.4376 20.3765 21.4376 20.1169C21.4376 19.5763 21.0694 19.306 20.3331 19.306H19.717Z" fill="white"/>
    <ellipse cx="4.27184" cy="6.42219" rx="4.27184" ry="4.42219" fill="#EE9A4D"/>
    <path d="M6.57866 8.77637H5.9849L5.49968 7.49307H3.55878L3.10228 8.77637H2.50533L4.26108 4.19865H4.81653L6.57866 8.77637ZM5.3241 7.01104L4.60584 5.06056C4.58243 4.99672 4.55902 4.89457 4.53561 4.75411H4.52284C4.50156 4.88393 4.47709 4.98608 4.44942 5.06056L3.73754 7.01104H5.3241Z" fill="white"/>
    </svg>
  );
};

export default Abnormality;
