import React, {useEffect, useState} from 'react';
import {useDrop, XYCoord} from 'react-dnd';
import update from 'immutability-helper';
import DnDBox, {DnDBoxTypes, IItem} from './DnDBox';
import ProcessTool, {PredefinedTools, ProcessToolTypes} from './ProcessTool';
import _ from 'lodash';
import Xarrow, {xarrowPropsType} from 'react-xarrows';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  makeStyles,
  Button,
  Theme,
  Typography,
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import {useHistory} from 'react-router-dom';
import CircularProgress from '@material-ui/core/CircularProgress';
import axios from 'axios';
import {useDispatch} from 'react-redux';
import {getResult} from '~/features/Result/slice';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    width: '100%',
    minHeight: 1200,
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
  accordion: {
    width: 280,
    padding: 16,
  },
  wrapper: {
    position: 'absolute',
    right: 16,
    display: 'flex',
    alignItems: 'center',
  },
  accordionRoot: {
    border: `1px solid ${theme.palette.grey['300']}`,
  },
  buttonProgress: {
    marginRight: 16,
  },
}));

interface IArrow {
  start: string;
  end: string;
}

const LineStyle: Partial<xarrowPropsType> = {
  path: 'straight',
  headSize: 0,
  strokeWidth: 3,
  color: '#113366',
};

const Playground: React.FC = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();

  const [arrows, setArrows] = useState<{[key: string]: IArrow}>({});
  const [tools, setTools] = useState<{[key: string]: IItem}>({});
  const [showResult, setShowResult] = useState(false);
  const [divY, setDivY] = useState(0);
  const [divX, setDivX] = useState(0);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setShowResult(
      _.filter(tools, tool => tool.type === DnDBoxTypes.Result).length > 0,
    );
  }, [tools]);

  const [, drop] = useDrop({
    accept: Object.keys(DnDBoxTypes),
    drop: (item: IItem, monitor) => {
      if (item.id === undefined) {
        const id = Math.random().toString(36).substr(2, 11);
        const client = monitor.getClientOffset() as XYCoord;

        if (client) {
          setTools(t =>
            update(t, {
              [id]: {
                $set: {
                  ...item,
                  id,
                  top: client.y - divY,
                  left: client.x - divX,
                },
              },
            }),
          );
        }
      } else if (item.connector) {
        const dropResult = monitor.getDropResult();
        if (
          dropResult &&
          item.id !== dropResult.id &&
          (dropResult.type === DnDBoxTypes.Tool ||
            dropResult.type === DnDBoxTypes.Result)
        ) {
          const id = item.id;
          setArrows(t =>
            update(t, {
              [`${id}-${dropResult.id}`]: {
                $set: {start: id, end: dropResult.id},
              },
            }),
          );
        }
      } else {
        const delta = monitor.getDifferenceFromInitialOffset() as XYCoord;
        const left = Math.round((item.left ?? 0) + delta.x);
        const top = Math.round((item.top ?? 0) + delta.y);
        moveTool(item.id, left, top);
      }
    },
    collect: monitor => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
    }),
  });

  const moveTool = (id: string, left: number, top: number) => {
    setTools(t =>
      update(t, {
        [id]: {
          $merge: {left, top},
        },
      }),
    );
  };

  const goToResult = async () => {
    setLoading(true);
    let ret: any[] = [];
    const variables = _.filter(tools, t => t.type === DnDBoxTypes.Variable);
    for (let variable of variables) {
      let item: any = {
        [variable.type]: variable,
      };
      let node = variable;
      do {
        if (node.id !== undefined) {
          const id = node.id;
          const key = _.chain(arrows)
            .keys()
            .filter(arw => arw.startsWith(id))
            .first()
            .value();
          if (key) {
            const edge = arrows[key];
            const end = _.chain(tools)
              .filter(tool => tool.id === edge.end)
              .first()
              .value();
            item = update(item, {
              [end.type]: (t: any) =>
                update(t || [], {
                  $push: [end],
                }),
            });
            node = end;
          } else {
            ret = [...ret, item];
            break;
          }
        } else {
          break;
        }
      } while (true);
    }
    dispatch(getResult(ret));
    history.push('/result');
    // window.setTimeout(() => {
    //   history.push('/result');
    // }, 2000);
  };

  const test = () => {
    let ret: any[] = [];
    const variables = _.filter(tools, t => t.type === DnDBoxTypes.Variable);
    for (let variable of variables) {
      let item: any = {
        [variable.type]: variable,
      };
      let node = variable;
      do {
        if (node.id !== undefined) {
          const id = node.id;
          const key = _.chain(arrows)
            .keys()
            .filter(arw => arw.startsWith(id))
            .first()
            .value();
          if (key) {
            const edge = arrows[key];
            const end = _.chain(tools)
              .filter(tool => tool.id === edge.end)
              .first()
              .value();
            item = update(item, {
              [end.type]: (t: any) =>
                update(t || [], {
                  $push: [end],
                }),
            });
            node = end;
          } else {
            ret = [...ret, item];
            break;
          }
        } else {
          break;
        }
      } while (true);
    }
    console.log(ret);
  };

  test();

  return (
    <div
      ref={el => {
        const rect = el?.getBoundingClientRect();
        if (rect) {
          setDivX(rect.x);
          setDivY(rect.y);
        }
        drop(el);
      }}
      className={classes.root}
    >
      {showResult && (
        <div className={classes.wrapper}>
          {loading && (
            <CircularProgress size={24} className={classes.buttonProgress} />
          )}
          <Button variant="outlined" color="primary" onClick={goToResult}>
            결과 확인
          </Button>
        </div>
      )}
      <div className={classes.accordion}>
        <Accordion classes={{root: classes.accordionRoot}}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography className={classes.heading}>데이터 전처리</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <div>
              <ProcessTool item={PredefinedTools[ProcessToolTypes.MISSING]} />
              <ProcessTool item={PredefinedTools[ProcessToolTypes.DUPLICATE]} />
              <ProcessTool item={PredefinedTools[ProcessToolTypes.OUTLIER]} />
              <ProcessTool item={PredefinedTools[ProcessToolTypes.NORMALIZE]} />
              <ProcessTool
                item={PredefinedTools[ProcessToolTypes.ONE_HOT_ENCODING]}
              />
              <ProcessTool item={PredefinedTools[ProcessToolTypes.BINNING]} />
            </div>
          </AccordionDetails>
        </Accordion>

        <Accordion classes={{root: classes.accordionRoot}}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel2a-content"
            id="panel2a-header"
          >
            <Typography className={classes.heading}>분석도구</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <div>
              <ProcessTool item={PredefinedTools[ProcessToolTypes.MEAN]} />
              <ProcessTool item={PredefinedTools[ProcessToolTypes.STD]} />
              <ProcessTool item={PredefinedTools[ProcessToolTypes.FREQUENCY]} />
              <ProcessTool
                item={PredefinedTools[ProcessToolTypes.GROUP_COMPARE]}
              />
              <ProcessTool
                item={PredefinedTools[ProcessToolTypes.REGRESSION]}
              />
              <ProcessTool
                item={PredefinedTools[ProcessToolTypes.CORRELATION]}
              />
            </div>
          </AccordionDetails>
        </Accordion>

        <Accordion classes={{root: classes.accordionRoot}}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel3a-content"
            id="panel3a-header"
          >
            <Typography className={classes.heading}>분석결과</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <div>
              <ProcessTool
                item={PredefinedTools[ProcessToolTypes.DEFAULT_RESULT]}
              />
              <ProcessTool
                item={PredefinedTools[ProcessToolTypes.IMAGE_RESULT]}
              />
              <ProcessTool
                item={PredefinedTools[ProcessToolTypes.CHART_RESULT]}
              />
            </div>
          </AccordionDetails>
        </Accordion>
      </div>
      {_.keys(tools).map(key => {
        const tool = tools[key];
        const {type} = tool;
        return type === DnDBoxTypes.Tool || type === DnDBoxTypes.Result ? (
          <ProcessTool
            id={key}
            key={key}
            item={tool}
            className={key}
            hideSourceOnDrag
            absolute
            onPlayground
          />
        ) : (
          <DnDBox
            id={key}
            key={key}
            className={key}
            item={tool}
            hideSourceOnDrag
            absolute
            onPlayground
          />
        );
      })}
      {_.map(arrows, arrow => (
        <Xarrow
          key={`arrow-${arrow.start}-${arrow.end}`}
          start={arrow.start}
          end={arrow.end}
          {...LineStyle}
        />
      ))}
    </div>
  );
};

export default Playground;
