import {
  CircularProgress,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@material-ui/core';
import Box from '@material-ui/core/Box';
import {makeStyles, Theme} from '@material-ui/core/styles';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import Typography from '@material-ui/core/Typography';
import _ from 'lodash';
import React from 'react';
import {useSelector} from 'react-redux';
import Table1 from '~/assets/images/table1.png';
import Table2 from '~/assets/images/table2.png';
import Table3 from '~/assets/images/table3.png';
import Table4 from '~/assets/images/table4.png';
import {METATABLE} from '~/features/MetaTable/slice';
import {RESULT} from '~/features/Result/slice';
import {RootState} from '~/features/rootReducer';

interface TabPanelProps {
  children?: React.ReactNode;
  index: any;
  value: any;
}

function TabPanel(props: TabPanelProps) {
  const {children, value, index, ...other} = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: any) {
  return {
    id: `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    display: 'flex',
    height: 224,
  },
  tabs: {
    borderRight: `1px solid ${theme.palette.divider}`,
  },
  table: {
    minWidth: 650,
  },
}));

const ResultScreen = () => {
  const classes = useStyles();
  const result = useSelector((state: RootState) => state[RESULT]);
  const meta = useSelector(
    (state: RootState) => state[METATABLE].metaTables[0],
  );
  const [value, setValue] = React.useState(0);

  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setValue(newValue);
  };
  return (
    <div className={classes.root}>
      {result.loading && <CircularProgress size={100} />}
      {!result.loading && (
        <>
          <Tabs
            orientation="vertical"
            variant="scrollable"
            value={value}
            onChange={handleChange}
            aria-label="Vertical tabs example"
            className={classes.tabs}
          >
            {_.map(result.result, (item, idx) => (
              <Tab label={`Result #${idx + 1}`} {...a11yProps(idx)} />
            ))}
            {/* <Tab label="Result #1" {...a11yProps(0)} />
            <Tab label="Result #2" {...a11yProps(1)} />
            <Tab label="Result #3" {...a11yProps(2)} />
            <Tab label="Result #4" {...a11yProps(3)} /> */}
          </Tabs>
          {_.map(result.result, (item, idx) => (
            <TabPanel value={value} index={idx}>
              <TableContainer component={Paper} elevation={0}>
                <Table className={classes.table} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell>Variables</TableCell>
                      <TableCell align="right">mean</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow>
                      <TableCell component="th" scope="row">
                        {
                          _.find(
                            meta?.headers,
                            h => h.colName === item[0].varname,
                          )?.var
                        }
                      </TableCell>
                      <TableCell align="right">
                        {item[0][item[0].varname]}
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </TabPanel>
          ))}
          {/* <TabPanel value={value} index={0}>
            <img src={Table1} />
          </TabPanel>
          <TabPanel value={value} index={1}>
            <img src={Table2} />
          </TabPanel>
          <TabPanel value={value} index={2}>
            <img src={Table3} />
          </TabPanel>
          <TabPanel value={value} index={3}>
            <img src={Table4} />
          </TabPanel> */}
        </>
      )}
    </div>
  );
};

export default ResultScreen;
