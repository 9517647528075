import {
  Divider,
  FormControlLabel,
  List,
  ListItem,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  makeStyles,
  RadioGroup,
  Switch,
  withStyles,
  Radio,
  Typography,
  Slider,
  Tooltip,
} from '@material-ui/core';
import React, {useState} from 'react';
import update from 'immutability-helper';

import MeanIcon from '../icons/Mean';
import StdIcon from '../icons/Std';
import FrequencyIcon from '../icons/Frequency';
import GroupCompareIcon from '../icons/GroupCompare';
import RegressionIcon from '../icons/Regression';
import CorrelationIcon from '../icons/Correlation';

import MissingIcon from '../icons/Missing';
import DuplicatedIcon from '../icons/Duplicated';
import OutlierIcon from '../icons/Outlier';
import NormalizeIcon from '../icons/Normalize';
import OneHotEncodingIcon from '../icons/OneHotEncoding';
import BinningIcon from '../icons/Binning';
import WaffleIcon from '../icons/Waffle';

import DnDBox, {DnDBoxTypes, IDnDBoxProps, IItem} from './DnDBox';
import clsx from 'clsx';
import _ from 'lodash';
import DefaultResultIcon from '../icons/DefaultResult';
import ImageResultIcon from '../icons/ImageResult';
import ChartResultIcon from '../icons/ChartResult';

const useStyles = makeStyles({
  root: {
    backgroundColor: 'white',
    padding: '0 1rem',
    cursor: 'move',
    display: 'flex',
    alignItems: 'center',
  },
  onPlayground: {
    backgroundColor: 'white',
    border: '2px solid #666666',
    borderRadius: 5,
  },
  list: {
    width: 245,
    marginLeft: 18,
    marginTop: 4,
    border: '1px solid #8A8886',
    borderRadius: 3,
    backgroundColor: 'white',
    zIndex: 99,
  },
  divider: {
    margin: '0 8px',
  },
  hide: {
    display: 'none',
  },
  radioGroupRoot: {
    flexDirection: 'row',
  },
  formControlLabel: {
    fontSize: 14,
  },
  label: {
    fontSize: 14,
  },
  secondaryRoot: {
    right: 8,
  },
  pane: {
    width: '100%',
  },
  paneHeader: {
    backgroundColor: '#0078D4',
    color: 'white',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    padding: '6px 14px',
    marginBottom: 6,
  },
  listItemNoTBPad: {
    paddingTop: 0,
    paddingBottom: 0,
  },
});

const NoPadList = withStyles({
  padding: {
    padding: 0,
  },
})(List);

export enum ProcessToolTypes {
  MISSING = 'Missing',
  DUPLICATE = 'Duplicate',
  OUTLIER = 'Outlier',
  NORMALIZE = 'Normalize',
  ONE_HOT_ENCODING = 'OneHotEncoding',
  BINNING = 'Binning',
  MEAN = 'Mean',
  STD = 'Std',
  FREQUENCY = 'Frequency',
  GROUP_COMPARE = 'GroupCompare',
  REGRESSION = 'Regression',
  CORRELATION = 'Correlation',
  DEFAULT_RESULT = 'Default_Result',
  IMAGE_RESULT = 'Image_Result',
  CHART_RESULT = 'Chart_Result',
}

export interface IToolOptions {
  type: string;
  name: string;
  paneType?: string;
  options?: IOptions[];
}

interface IOptions {
  name: string;
  value: string;
}

export const PredefinedTools: {[key in ProcessToolTypes]: IItem} = {
  [ProcessToolTypes.MISSING]: {
    name: '결측치',
    icon: <MissingIcon />,
    type: DnDBoxTypes.Tool,
    toolType: ProcessToolTypes.MISSING,
    options: [{name: '결측치 데이터 행 삭제', type: 'switch'}],
  },
  [ProcessToolTypes.DUPLICATE]: {
    name: '중복',
    icon: <DuplicatedIcon />,
    type: DnDBoxTypes.Tool,
    toolType: ProcessToolTypes.DUPLICATE,
    options: [{name: '중복 데이터 행 삭제', type: 'switch'}],
  },
  [ProcessToolTypes.OUTLIER]: {
    name: '이상치',
    icon: <OutlierIcon />,
    type: DnDBoxTypes.Tool,
    toolType: ProcessToolTypes.OUTLIER,
    options: [{name: '이상치 데이터 행 삭제', type: 'switch'}],
  },
  [ProcessToolTypes.NORMALIZE]: {
    name: '정규화',
    icon: <NormalizeIcon />,
    type: DnDBoxTypes.Tool,
    toolType: ProcessToolTypes.NORMALIZE,
    options: [
      {
        name: '표준화 기준값 입력',
        type: 'pane',
        paneType: 'mean-std',
      },
      {
        name: '최대-최소 기준값 입력',
        type: 'pane',
        paneType: 'min-max',
      },
    ],
  },
  [ProcessToolTypes.ONE_HOT_ENCODING]: {
    name: '원-핫인코딩',
    icon: <OneHotEncodingIcon />,
    type: DnDBoxTypes.Tool,
    toolType: ProcessToolTypes.ONE_HOT_ENCODING,
    options: [],
  },
  [ProcessToolTypes.BINNING]: {
    name: '구간화',
    icon: <BinningIcon />,
    type: DnDBoxTypes.Tool,
    toolType: ProcessToolTypes.BINNING,
    options: [
      {
        name: '구간화 값 입력',
        type: 'pane',
        paneType: 'binning',
      },
    ],
  },
  [ProcessToolTypes.MEAN]: {
    name: '평균계산',
    icon: <MeanIcon />,
    type: DnDBoxTypes.Tool,
    toolType: ProcessToolTypes.MEAN,
  },
  [ProcessToolTypes.STD]: {
    name: '분산계산',
    icon: <StdIcon />,
    type: DnDBoxTypes.Tool,
    toolType: ProcessToolTypes.STD,
  },
  [ProcessToolTypes.FREQUENCY]: {
    name: '빈도분석',
    icon: <FrequencyIcon />,
    type: DnDBoxTypes.Tool,
    toolType: ProcessToolTypes.FREQUENCY,
  },
  [ProcessToolTypes.GROUP_COMPARE]: {
    name: '그룹비교',
    icon: <GroupCompareIcon />,
    type: DnDBoxTypes.Tool,
    toolType: ProcessToolTypes.GROUP_COMPARE,
    options: [
      {
        name: '분산분석',
        type: 'select',
        options: [
          {name: '일변량', value: 'single'},
          {name: '다변량', value: 'multiple'},
        ],
      },
    ],
  },
  [ProcessToolTypes.REGRESSION]: {
    name: '회귀분석',
    icon: <RegressionIcon />,
    type: DnDBoxTypes.Tool,
    toolType: ProcessToolTypes.REGRESSION,
    options: [
      {
        name: '회귀분석',
        type: 'select',
        options: [{name: '선형', value: 'linear'}],
      },
    ],
  },
  [ProcessToolTypes.CORRELATION]: {
    name: '공분산분석',
    icon: <CorrelationIcon />,
    type: DnDBoxTypes.Tool,
    toolType: ProcessToolTypes.CORRELATION,
    options: [
      {
        name: '공분산분석',
        type: 'select',
        options: [{name: '공분산분석', value: 'correlation'}],
      },
    ],
  },
  [ProcessToolTypes.DEFAULT_RESULT]: {
    name: '통계 결과 확인',
    icon: <DefaultResultIcon />,
    type: DnDBoxTypes.Result,
    toolType: ProcessToolTypes.DEFAULT_RESULT,
  },
  [ProcessToolTypes.IMAGE_RESULT]: {
    name: '이미지 변환',
    icon: <ImageResultIcon />,
    type: DnDBoxTypes.Result,
    toolType: ProcessToolTypes.IMAGE_RESULT,
  },
  [ProcessToolTypes.CHART_RESULT]: {
    name: '챠트 변환',
    icon: <ChartResultIcon />,
    type: DnDBoxTypes.Result,
    toolType: ProcessToolTypes.CHART_RESULT,
  },
};

interface IValueLabelProps {
  children: React.ReactElement;
  open: boolean;
  value: number;
}

const ValueLabel = (props: IValueLabelProps) => {
  const {children, open, value} = props;

  return (
    <Tooltip open={open} enterTouchDelay={0} placement="top" title={value}>
      {children}
    </Tooltip>
  );
};

const ProcessTool = ({onPlayground, item, ...restProps}: IDnDBoxProps) => {
  const classes = useStyles();
  const [visibleOption, setVisibleOption] = useState(false);
  const [switchValue, setSwitchValue] = useState<boolean[]>(
    _.times(item.options?.length ?? 0, _.constant(false)),
  );
  const [selectValue, setSelectValue] = useState<string>();
  const [binValues, setBinValues] = useState<number[]>([0, 25, 75, 100]);

  const handleClick = () => {
    setVisibleOption(v => !!onPlayground && !v);
  };

  const handleSwitch = (
    evt: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    index: number,
  ) => {
    evt.stopPropagation();
    // @ts-ignore
    const v = update(switchValue, {$toggle: [index]});
    for (let i = 0; i < switchValue.length; i++) {
      if (i !== index) {
        v[i] = false;
      }
    }
    setSwitchValue(v);
  };

  const handleChange = (
    evt: React.ChangeEvent<HTMLInputElement>,
    value: string,
  ) => {
    evt.stopPropagation();
    setSelectValue(value);
  };
  const handlePropagation = (
    evt: React.MouseEvent<HTMLDivElement, MouseEvent>,
  ) => {
    evt.stopPropagation();
  };
  const handleRange = (
    evt: React.ChangeEvent<{}>,
    value: number | number[],
  ) => {
    setBinValues(
      update(value as number[], {
        0: {$set: 0},
        [(value as number[]).length - 1]: {$set: 100},
      }),
    );
  };

  return (
    <DnDBox
      onPlayground={onPlayground}
      item={item}
      {...restProps}
      classes={{root: onPlayground ? classes.onPlayground : classes.root}}
      onClick={handleClick}
    >
      {item.options && (
        <div
          className={clsx(classes.list, !visibleOption && classes.hide)}
          onClick={handlePropagation}
        >
          <NoPadList>
            <ListItem>
              <ListItemIcon>{item.icon}</ListItemIcon>
              <ListItemText primary={item.name} />
            </ListItem>
            <Divider className={classes.divider} />

            {_.map(item.options, (option, indx) => (
              <div key={`item-option-${item.name}-${option.name}`}>
                <ListItem className={classes.listItemNoTBPad}>
                  <ListItemText
                    classes={{root: classes.label}}
                    primary={option.name}
                  />
                  <ListItemSecondaryAction
                    classes={{root: classes.secondaryRoot}}
                  >
                    {option.type === 'switch' && (
                      <Switch
                        edge="end"
                        name={option.name}
                        checked={switchValue[indx]}
                        inputProps={{'aria-label': 'secondary checkbox'}}
                        onClick={evt => handleSwitch(evt, indx)}
                      />
                    )}
                    {option.type === 'select' && (
                      <RadioGroup
                        classes={{root: classes.radioGroupRoot}}
                        aria-label="options"
                        name="options"
                        value={selectValue}
                        onChange={handleChange}
                      >
                        {_.map(option.options, opt => (
                          <FormControlLabel
                            classes={{label: classes.formControlLabel}}
                            value={opt.value}
                            control={<Radio size="small" />}
                            label={opt.name}
                          />
                        ))}
                      </RadioGroup>
                    )}
                    {option.type === 'pane' && (
                      <Switch
                        edge="end"
                        name={option.name}
                        checked={switchValue[indx]}
                        inputProps={{'aria-label': 'secondary checkbox'}}
                        onClick={evt => handleSwitch(evt, indx)}
                      />
                    )}
                  </ListItemSecondaryAction>
                </ListItem>

                {option.paneType === 'binning' && (
                  <ListItem
                    className={clsx(!switchValue[indx] && classes.hide)}
                  >
                    <div className={classes.pane}>
                      <div className={classes.paneHeader}>
                        <WaffleIcon />
                        <Typography style={{marginTop: 2, marginLeft: 11}}>
                          {option.name}
                        </Typography>
                      </div>
                      {/* <div>
                        <Typography>최소값: 0</Typography>
                        <Typography>최대값: 100</Typography>
                      </div> */}
                      <Divider style={{margin: '8px 0'}} />
                      <div>
                        <Typography>구간설정</Typography>
                        <div style={{padding: '0 8px'}}>
                          <Slider
                            aria-labelledby="track-false-range-slider"
                            value={binValues}
                            onChange={handleRange}
                            ValueLabelComponent={ValueLabel}
                            marks={[{value: 0}, {value: 100}]}
                          />
                        </div>
                      </div>
                    </div>
                  </ListItem>
                )}
                {option.paneType === 'min-max' && (
                  <ListItem
                    className={clsx(!switchValue[indx] && classes.hide)}
                  >
                    <div className={classes.pane}>
                      <div className={classes.paneHeader}>
                        <WaffleIcon />
                        <Typography style={{marginTop: 2, marginLeft: 11}}>
                          {option.name}
                        </Typography>
                      </div>
                      {/* <div>
                        <Typography>최소값: 0</Typography>
                        <Typography>최대값: 100</Typography>
                      </div> */}
                    </div>
                  </ListItem>
                )}
                {option.paneType === 'mean-std' && (
                  <ListItem
                    className={clsx(!switchValue[indx] && classes.hide)}
                  >
                    <div className={classes.pane}>
                      <div className={classes.paneHeader}>
                        <WaffleIcon />
                        <Typography style={{marginTop: 2, marginLeft: 11}}>
                          {option.name}
                        </Typography>
                      </div>
                      {/* <div>
                        <Typography>평균값: 25</Typography>
                        <Typography>분산값: 10</Typography>
                      </div> */}
                    </div>
                  </ListItem>
                )}
              </div>
            ))}
          </NoPadList>
        </div>
      )}
    </DnDBox>
  );
};

export default ProcessTool;
