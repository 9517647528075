import { Badge, withStyles } from "@material-ui/core";
import React from "react";
import { IconButton } from "./IconButton";

export interface IBadgeButtonProps {
  badgeCount: number;
  icon: React.ReactNode;
}

const StyledBadge = withStyles((theme) => ({
  anchorOriginTopRightRectangle: {
    backgroundColor: theme.palette.primary.dark,
    "&:hover": {
      backgroundColor: theme.palette.primary.contrastText,
      color: theme.palette.primary.dark,
    },
  },
}))(Badge);

const StyledIconButton = withStyles((theme) => ({
  root: {
    padding: 0,
    minWidth: 48,
    minHeight: 48,
    "&:hover": {
      backgroundColor: theme.palette.primary.dark,
    },
    "&:active": {
      ripple: 0,
    },
  },
}))(IconButton);

const BadgeButton: React.FC<IBadgeButtonProps> = (props) => {
  const { icon, badgeCount } = props;
  return (
    <StyledIconButton
      aria-label="badge button"
      color="primary"
      variant="contained"
      icon={
        <StyledBadge badgeContent={badgeCount} color="primary">
          {icon}
        </StyledBadge>
      }
    ></StyledIconButton>
  );
};

export default BadgeButton;
