import React from 'react';

const Normalize = () => {
  return (
    <svg width="36" height="36" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="16.8042" y="3.5" width="7.69565" height="19.6522" fill="#63D63B" stroke="#63D63B"/>
    <path d="M22.6528 16H21.6216L21.3228 15.0654H19.8286L19.5327 16H18.5073L20.0366 11.7988H21.1587L22.6528 16ZM21.106 14.3389L20.6548 12.9268C20.6216 12.8213 20.5981 12.6953 20.5845 12.5488H20.561C20.5513 12.6719 20.5269 12.7939 20.4878 12.915L20.0308 14.3389H21.106Z" fill="white"/>
    <rect y="3" width="11.9565" height="5.43478" fill="#696969"/>
    <rect y="10.6084" width="7.6087" height="5.43478" fill="#696969"/>
    <rect y="18.2178" width="11.9565" height="5.43478" fill="#696969"/>
    </svg>
  );
};

export default Normalize;
