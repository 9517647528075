import React, {useCallback, useState} from 'react';
import AcUnitIcon from '@material-ui/icons/AcUnit';
import {
  Button,
  makeStyles,
  Menu,
  MenuItem,
  MenuProps,
  Theme,
  withStyles,
  ListItemIcon,
  ListItemText,
  Dialog,
  LinearProgress,
} from '@material-ui/core';
import {BrowserRouter, Switch, Route, Link} from 'react-router-dom';
import {DndProvider, DropTargetMonitor} from 'react-dnd';
import {HTML5Backend} from 'react-dnd-html5-backend';
import {useDispatch, useSelector} from 'react-redux';

import SaveIcon from '@material-ui/icons/Save';
import PlayArrowIcon from '@material-ui/icons/PlayArrow';
import FolderOpenOutlinedIcon from '@material-ui/icons/FolderOpenOutlined';
import RedoIcon from '@material-ui/icons/Redo';

import Header from '../components/Header';
import NavMenu from '../components/NavMenu';

import * as MetaTableAction from '../features/MetaTable/slice';
import FileDropBox from '../components/FileDropBox';
import {uploadRequest} from '../features/UploadFile/slice';
import * as TableAction from '../features/Table/slice';
import {RootState} from '../features/rootReducer';
import {ColDef, DataGrid, GridOverlay, RowId} from '@material-ui/data-grid';
import {ITable} from '../features/Table/slice';
import _ from 'lodash';
import AnalyzeScreen from '~/screens/AnalyzeScreen';
import ResultScreen from '~/screens/ResultScreen';
import MapScreen from '~/screens/MapScreen';

const columns: ColDef[] = [
  {field: 'name', headerName: '데이터 이름', width: 240},
  {field: 'filename', headerName: '파일 이름', width: 200},
  {field: 'filetype', headerName: '파일 종류', width: 120},
];

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    width: 'calc(100vw - 148px)',
  },
  menu: {
    borderBottom: `1px solid ${theme.palette.grey['300']}`,
    padding: '6px 20px',
  },
  pane: {
    flex: 1,
    padding: 20,
    display: 'flex',
    flexDirection: 'column',
  },
  statPane: {
    width: '30%',
    marginRight: 20,
  },
  dataPane: {
    width: 'calc(70% - 20px)',
  },
  topPane: {display: 'flex', marginBottom: 20, height: '30%'},
  bottomPane: {
    width: '100%',
  },
  fileDialog: {
    width: '70vw',
    height: '50vh',
    padding: '24px 24px',
  },
}));

function CustomLoadingOverlay() {
  return (
    <GridOverlay>
      <div style={{position: 'absolute', top: 0, width: '100%'}}>
        <LinearProgress />
      </div>
    </GridOverlay>
  );
}

const StyledMenu = withStyles({
  paper: {
    border: '1px solid #d3d4d5',
  },
})((props: MenuProps) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'center',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'center',
    }}
    {...props}
  />
));

const StyledMenuItem = withStyles(theme => ({
  root: {
    '& .MuiListItemIcon-root, & .MuiListItemText-primary': {
      minWidth: 36,
    },
    '&:focus': {
      backgroundColor: theme.palette.primary.main,
      '& .MuiListItemIcon-root, & .MuiListItemText-primary': {
        color: theme.palette.common.white,
      },
    },
  },
}))(MenuItem);

function App() {
  const classes = useStyles();
  const dispatch = useDispatch();

  const tableInfo = useSelector((state: RootState) => state[TableAction.TABLE]);

  const [openFile, setOpenFile] = useState(false);
  const [openList, setOpenList] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [selectedRows, setSelectedRows] = useState<RowId[]>([]);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLoad = () => {
    setAnchorEl(null);
    setOpenFile(true);
  };

  const handlePublic = () => {
    setAnchorEl(null);
    setOpenList(true);
    dispatch(TableAction.load());
  };

  const handleLoadData = () => {
    let data: ITable[] = [];
    for (let indx of selectedRows) {
      const table = _.find(
        tableInfo.tables,
        t => t.id === parseInt(indx as string),
      );
      table && data.push(table);
    }
    dispatch(MetaTableAction.load(_.map(data, d => d.id)));
    setOpenList(false);
  };

  const handleCloseDialog = () => {
    setOpenFile(false);
  };

  const handleCloseListDialog = () => {
    setOpenList(false);
  };

  const handleFileDrop = useCallback(
    (item: any, monitor: DropTargetMonitor) => {
      if (monitor) {
        const files = monitor.getItem().files;
        dispatch(uploadRequest(files));
      }
    },
    [dispatch],
  );

  return (
    <div>
      <BrowserRouter>
        <Header title="빅데이터 분석 플랫폼" />
        <div className={classes.root}>
          <NavMenu
            items={[
              {
                icon: <AcUnitIcon />,
                title: '데이터 분석',
                linkto: '/',
                disabled: false,
                active: true,
              },
              {
                icon: <AcUnitIcon />,
                title: '지도 분석',
                linkto: '/map',
                disabled: false,
                active: false,
              },
            ]}
          />
          <div className={classes.container}>
            <div className={classes.menu}>
              <Button startIcon={<SaveIcon />}>저장하기</Button>
              <Button
                aria-controls="customized-menu"
                aria-haspopup="true"
                startIcon={<FolderOpenOutlinedIcon />}
                onClick={handleClick}
              >
                불러오기
              </Button>
              <Button startIcon={<RedoIcon />}>내보내기</Button>

              <StyledMenu
                id="customized-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
              >
                <StyledMenuItem onClick={handleLoad}>
                  <ListItemIcon>
                    <PlayArrowIcon fontSize="small" />
                  </ListItemIcon>
                  <ListItemText primary="파일 불러오기" />
                </StyledMenuItem>
                <StyledMenuItem onClick={handlePublic}>
                  <ListItemIcon>
                    <PlayArrowIcon fontSize="small" />
                  </ListItemIcon>
                  <ListItemText primary="공공데이터 불러오기" />
                </StyledMenuItem>
              </StyledMenu>
            </div>
            <Switch>
              <Route path="/" exact component={AnalyzeScreen} />
              <Route path="/result" component={ResultScreen} />
              <Route path="/map" component={MapScreen} />
            </Switch>
          </div>
        </div>

        <DndProvider backend={HTML5Backend}>
          <Dialog
            classes={{paper: classes.fileDialog}}
            onClose={handleCloseDialog}
            aria-labelledby="file-upload-dialog"
            open={openFile}
          >
            <FileDropBox onDrop={handleFileDrop} />
          </Dialog>
          <Dialog
            classes={{paper: classes.fileDialog}}
            onClose={handleCloseListDialog}
            aria-labelledby="data-list-dialog"
            open={openList}
          >
            <div style={{display: 'flex', height: '100%'}}>
              <div style={{flexGrow: 1}}>
                <DataGrid
                  onSelectionChange={newSelection => {
                    setSelectedRows(newSelection.rowIds);
                  }}
                  components={{loadingOverlay: CustomLoadingOverlay}}
                  rows={tableInfo.tables}
                  columns={columns}
                  checkboxSelection
                  loading={tableInfo.loading}
                />
              </div>
            </div>
            <div
              style={{
                display: 'flex',
                justifyContent: 'flex-end',
                marginTop: 16,
              }}
            >
              <Button
                variant="contained"
                color="primary"
                onClick={handleLoadData}
              >
                불러오기
              </Button>
            </div>
          </Dialog>
        </DndProvider>
      </BrowserRouter>
    </div>
  );
}

export default App;
