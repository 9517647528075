import {
  AppBar,
  createStyles,
  makeStyles,
  Theme,
  Toolbar,
  Typography,
  withStyles,
} from '@material-ui/core';
import React from 'react';
import {IconButton} from './IconButton';
import AppsIcon from '@material-ui/icons/Apps';
import BadgeButton from './BadgeButton';
import AvatarButton from './AvatarButton';
import NotificationsNoneIcon from '@material-ui/icons/NotificationsNone';
import SettingsOutlinedIcon from '@material-ui/icons/SettingsOutlined';
import HelpOutlineOutlinedIcon from '@material-ui/icons/HelpOutlineOutlined';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    grow: {
      flexGrow: 1,
    },
  }),
);

const StyledToolbar = withStyles(theme => ({
  gutters: {
    padding: 0,
  },
}))(Toolbar);

const StyledTypography = withStyles(theme => ({
  root: {
    paddingLeft: theme.spacing(1),
  },
}))(Typography);

export interface IHeaderProps {
  title: string;
}

const Header: React.FunctionComponent<IHeaderProps> = props => {
  const classes = useStyles();
  const {title} = props;

  return (
    <AppBar position="static">
      <StyledToolbar variant="dense">
        <IconButton icon={<AppsIcon />} color="primary" variant="contained" />
        <StyledTypography variant="body1">{title}</StyledTypography>
        <div className={classes.grow} />
        <div>
          <BadgeButton badgeCount={8} icon={<NotificationsNoneIcon />} />
          <IconButton
            icon={<SettingsOutlinedIcon />}
            color="primary"
            variant="contained"
          />
          <IconButton
            icon={<HelpOutlineOutlinedIcon />}
            color="primary"
            variant="contained"
          />
          <AvatarButton />
        </div>
      </StyledToolbar>
    </AppBar>
  );
};

export default Header;
