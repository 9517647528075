import {
  Avatar,
  Badge,
  createStyles,
  Theme,
  withStyles,
} from "@material-ui/core";
import React from "react";
import { IconButton } from "./IconButton";

export interface IAvatarButtonProps {}

const StyledIconButton = withStyles((theme) => ({
  root: {
    padding: 0,
    minWidth: 48,
    minHeight: 48,
    "&:focus": {
      backgroundColor: theme.palette.common.white,
    },
  },
}))(IconButton);

const StyledBadge = withStyles((theme: Theme) =>
  createStyles({
    badge: {
      backgroundColor: "#44b700",
      color: "#44b700",
      boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
      "&::after": {
        position: "absolute",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        borderRadius: "50%",
        content: '""',
      },
    },
  })
)(Badge);

const StyledAvatar = withStyles((theme) => ({
  root: {
    width: "28px",
    height: "28px",
  },
}))(Avatar);

const BadgeButton: React.FC<IAvatarButtonProps> = (props) => {
  return (
    <StyledIconButton
      aria-label="show 4 new mails"
      color="primary"
      variant="contained"
      icon={
        <StyledBadge
          overlap="circle"
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
          variant="dot"
        >
          <StyledAvatar alt="Remy Sharp" />
        </StyledBadge>
      }
    ></StyledIconButton>
  );
};

export default BadgeButton;
