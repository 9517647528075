import React from 'react';

const Outlier = () => {
  return (
    <svg
      width="36"
      height="36"
      viewBox="0 0 25 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0)">
        <circle
          cx="13.0256"
          cy="12.5703"
          r="1.97371"
          transform="rotate(-45 13.0256 12.5703)"
          fill="#757575"
        />
        <circle
          cx="9.76952"
          cy="15.8262"
          r="1.31581"
          transform="rotate(-45 9.76952 15.8262)"
          fill="#757575"
        />
        <circle
          cx="6.74572"
          cy="18.8486"
          r="0.986856"
          transform="rotate(-45 6.74572 18.8486)"
          fill="#757575"
        />
        <circle
          cx="18.8409"
          cy="6.75488"
          r="0.986856"
          transform="rotate(-45 18.8409 6.75488)"
          fill="#757575"
        />
        <circle
          cx="16.2817"
          cy="9.31152"
          r="1.31581"
          transform="rotate(-45 16.2817 9.31152)"
          fill="#757575"
        />
        <ellipse
          cx="1.94261"
          cy="23.7336"
          rx="0.506082"
          ry="0.506065"
          fill="#757575"
        />
        <ellipse
          cx="20.9753"
          cy="4.70235"
          rx="0.506082"
          ry="0.506065"
          fill="#757575"
        />
        <ellipse
          cx="4.22825"
          cy="21.4504"
          rx="0.506082"
          ry="0.506065"
          fill="#757575"
        />
        <ellipse
          cx="22.8362"
          cy="2.84103"
          rx="0.506082"
          ry="0.506065"
          fill="#757575"
        />
        <ellipse
          cx="4.94641"
          cy="5.1205"
          rx="4.94641"
          ry="5.1205"
          fill="#EE4D87"
        />
        <path
          d="M7.40417 7.71875H6.71665L6.15481 6.23282H3.90743L3.37886 7.71875H2.68764L4.72063 2.4182H5.36379L7.40417 7.71875ZM5.95151 5.67468L5.11983 3.41621C5.09273 3.34229 5.06562 3.22401 5.03851 3.06137H5.02373C4.99909 3.21168 4.97075 3.32997 4.93871 3.41621L4.11443 5.67468H5.95151Z"
          fill="white"
        />
        <ellipse
          cx="20.0538"
          cy="19.8109"
          rx="4.94641"
          ry="5.1205"
          fill="#EE4D87"
        />
        <path
          d="M18.563 22.8994V17.5989H20.0712C20.5295 17.5989 20.893 17.711 21.1616 17.9352C21.4302 18.1595 21.5645 18.4515 21.5645 18.8113C21.5645 19.1119 21.4832 19.3731 21.3205 19.5949C21.1579 19.8167 20.9336 19.9744 20.6478 20.068V20.0828C21.0051 20.1247 21.2909 20.2602 21.5053 20.4894C21.7197 20.7161 21.8269 21.0118 21.8269 21.3765C21.8269 21.8299 21.6643 22.1971 21.339 22.478C21.0137 22.759 20.6034 22.8994 20.1081 22.8994H18.563ZM19.184 18.1607V19.8721H19.8198C20.1599 19.8721 20.4272 19.7908 20.6219 19.6282C20.8166 19.4631 20.9139 19.2314 20.9139 18.9332C20.9139 18.4182 20.5751 18.1607 19.8974 18.1607H19.184ZM19.184 20.4303V22.3376H20.0268C20.3915 22.3376 20.6737 22.2513 20.8733 22.0788C21.0753 21.9063 21.1764 21.6698 21.1764 21.3691C21.1764 20.7432 20.75 20.4303 19.8974 20.4303H19.184Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0">
          <rect width="25" height="25" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default Outlier;
