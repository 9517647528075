import React from 'react';

const Duplicated = () => {
  return (
    <svg
      width="36"
      height="36"
      viewBox="0 0 25 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0)">
        <rect
          x="0.5"
          y="3.83301"
          width="20.6669"
          height="20.6669"
          fill="#757575"
          stroke="#757575"
        />
        <rect
          x="3.83301"
          y="0.5"
          width="20.6669"
          height="20.6669"
          stroke="#BB4DEE"
        />
        <rect x="3.33301" width="21.6669" height="21.6669" fill="#BB4DEE" />
        <path
          d="M16.6807 13.3877H14.9619L14.4639 11.8301H11.9736L11.4805 13.3877H9.77148L12.3203 6.38574H14.1904L16.6807 13.3877ZM14.1025 10.6191L13.3506 8.26562C13.2952 8.08984 13.2562 7.87988 13.2334 7.63574H13.1943C13.1781 7.84082 13.1374 8.04427 13.0723 8.24609L12.3105 10.6191H14.1025Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0">
          <rect width="25" height="25" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default Duplicated;
