import React from 'react';

const ChartResult = () => {
  return (
    <svg
      width="36"
      height="36"
      viewBox="0 0 25 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0)">
        <g clipPath="url(#clip1)">
          <rect
            x="20.1666"
            y="8.93366"
            width="3.06667"
            height="15.3333"
            stroke="#4F4F4F"
            strokeWidth="1.53333"
          />
          <rect
            x="14.0333"
            y="15.0665"
            width="3.06667"
            height="9.2"
            stroke="#4F4F4F"
            strokeWidth="1.53333"
          />
          <rect
            x="1.76667"
            y="19.6671"
            width="3.06667"
            height="4.6"
            stroke="#4F4F4F"
            strokeWidth="1.53333"
          />
          <rect
            x="7.89997"
            y="12.0001"
            width="3.06667"
            height="12.2667"
            stroke="#4F4F4F"
            strokeWidth="1.53333"
          />
          <circle
            cx="3.3"
            cy="12.0002"
            r="1.53333"
            stroke="#4F4F4F"
            strokeWidth="1.53333"
          />
          <circle
            cx="9.4333"
            cy="5.86738"
            r="1.53333"
            stroke="#4F4F4F"
            strokeWidth="1.53333"
          />
          <circle
            cx="15.5666"
            cy="8.93281"
            r="1.53333"
            stroke="#4F4F4F"
            strokeWidth="1.53333"
          />
          <circle
            cx="21.6999"
            cy="2.8"
            r="1.53333"
            stroke="#4F4F4F"
            strokeWidth="1.53333"
          />
          <path
            d="M4.8335 11.233L7.90016 7.39967M10.9668 6.63301L14.0335 8.16634M17.1002 7.39967L20.1668 4.33301"
            stroke="#4F4F4F"
            strokeWidth="1.53333"
          />
        </g>
      </g>
      <defs>
        <clipPath id="clip0">
          <rect width="25" height="25" fill="white" />
        </clipPath>
        <clipPath id="clip1">
          <rect
            width="23"
            height="24.5333"
            fill="white"
            transform="translate(1 0.5)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default ChartResult;
