import React from 'react';

const GroupCompare = () => {
  return (
    <svg
      width="36"
      height="36"
      viewBox="0 0 25 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0)">
        <path
          d="M20.375 8L15.875 12.5H19.25C19.25 16.2238 16.2237 19.25 12.5 19.25C11.3638 19.25 10.2837 18.9687 9.35 18.4625L7.7075 20.105C9.09125 20.9825 10.7338 21.5 12.5 21.5C17.4725 21.5 21.5 17.4725 21.5 12.5H24.875L20.375 8ZM5.75 12.5C5.75 8.77625 8.77625 5.75 12.5 5.75C13.6362 5.75 14.7163 6.03125 15.65 6.5375L17.2925 4.895C15.9088 4.0175 14.2662 3.5 12.5 3.5C7.5275 3.5 3.5 7.5275 3.5 12.5H0.125L4.625 17L9.125 12.5H5.75Z"
          fill="#605E5C"
        />
        <circle cx="4.3125" cy="20.3125" r="4.3125" fill="#2196F3" />
        <path
          d="M6.45184 22.959H5.81454L5.29374 21.5816H3.21053L2.72056 22.959H2.07983L3.96432 18.0456H4.5605L6.45184 22.959ZM5.10529 21.0642L4.33437 18.9707C4.30924 18.9022 4.28411 18.7926 4.25899 18.6418H4.24528C4.22244 18.7811 4.19617 18.8908 4.16647 18.9707L3.4024 21.0642H5.10529Z"
          fill="white"
        />
        <circle cx="20.3125" cy="4.3125" r="4.3125" fill="#2196F3" />
        <path
          d="M18.9022 6.95898V2.04561H20.3001C20.725 2.04561 21.0619 2.14954 21.3109 2.35741C21.5599 2.56527 21.6843 2.83595 21.6843 3.16945C21.6843 3.44813 21.609 3.69025 21.4582 3.89584C21.3074 4.10142 21.0996 4.24761 20.8346 4.33441V4.34811C21.1658 4.38694 21.4308 4.51258 21.6295 4.72501C21.8282 4.93516 21.9276 5.20927 21.9276 5.54733C21.9276 5.96763 21.7769 6.30798 21.4753 6.56838C21.1738 6.82878 20.7935 6.95898 20.3344 6.95898H18.9022ZM19.4778 2.56642V4.15281H20.0671C20.3823 4.15281 20.6302 4.07743 20.8106 3.92667C20.9911 3.77363 21.0813 3.55891 21.0813 3.28252C21.0813 2.80512 20.7672 2.56642 20.1391 2.56642H19.4778ZM19.4778 4.67019V6.43818H20.259C20.5971 6.43818 20.8586 6.35823 21.0436 6.19834C21.2309 6.03844 21.3246 5.81915 21.3246 5.54048C21.3246 4.96029 20.9294 4.67019 20.1391 4.67019H19.4778Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0">
          <rect width="25" height="25" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default GroupCompare;
